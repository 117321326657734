// import sha from 'js-sha256';
export function bb64encod(password){
    // const arr = sha.array(password);
    // var ascii = new Uint8Array(password);
    // var b64encoded = btoa(String.fromCharCode.apply(null, ascii));
    var b64encoded = btoa(password);
    
    var utf8 = unescape(encodeURIComponent(b64encoded));
    var arr2 = [];
    for (var i = 0; i < utf8.length; i++) {
        arr2.push(utf8.charCodeAt(i));
    }
    var a = new Uint8Array(arr2);
    var bb64encoded = btoa(String.fromCharCode.apply(null, a));
    return bb64encoded
}