import React, { Component } from "react";
import Logo from "../images/things-logo.png";

export default class ResponsiveDialog extends Component {
  componentDidMount() {
    // this.authenticate().then(() => {
    //   const loader = document.getElementById('ipl-progress-indicator')
    //   if(loader){
    //     // fade out
    //     loader.classList.add('available')
    //     setTimeout(() => {
    //       // remove from DOM
    //       loader.outerHTML = ''
    //     }, 500)
    //   }
    // })
    // const loader = document.getElementById('ipl-progress-indicator')
    // loader.outerHTML='';
  }

  render() {
    return (
      <div
        className="div_loader"
        style={{ marginTop: -70, marginLeft: -20, marginBottom: 70 }}
      >
        {this.props.loading ? (
          <div className="ipl-progress-indicator" id="ipl-progress-indicator">
            <div className="ipl-progress-indicator-head">
              <div className="first-indicator"></div>
              <div className="second-indicator"></div>
            </div>
            <div className="insp-logo-frame" style={{ borderRadius: 100 }}>
              <img src={Logo} className="loading_logo" alt="logo" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
