const units = [
    "Unit", "m", "mm", "cm", "kg", "gm", "C", "K", "F", "Pa", "Kgf", "kmph", "m/s"
]

const param_types = [

    "digital",
    "digital_in",
    "analog_in",
    "analog",
    "GPS",
    "text"
]

//Activity Logs Table start
const activityLogs_header = [
    'User Id', 'User Name', 'User Role', 'Last Updated'
]
function activityLogs(log_id, log_name, log_time, log_role, log_company, time, log_detail) {
    return { log_id, log_name, log_time, log_role, log_company, time, log_detail };
}
const activityLogs_rows = [
    activityLogs('user-001', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-002', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-003', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-004', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-005', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-006', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-007', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-008', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-009', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-010', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    activityLogs('user-011', 'Sapan', 'Given access 10 days ago', 'Ui developer', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
];
//Activity Logs Table end

// User Detail table List start
const userTable_header = [
    'User Id', 'User Name', 'User Role', 'Last Updated', 'Action'
]
function userTable(user_id, user_name, user_time, role, company, time, detail) {
    return { user_id, user_name, user_time, role, company, time, detail };
}
const userTable_rows = [
    userTable('user-001', 'Bill Gates', 'Given access 10 days ago', 'Admin', 'Epsum Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-002', 'Brian Acton', 'Given access 10 days ago', 'Manager', 'TCS', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-003', 'Jan Koum', 'Given access 10 days ago', 'Admin', 'Epsum Things', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-004', 'Evan Williams', 'Given access 10 days ago', 'Manager', 'D & D Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-005', 'Biz Stone', 'Given access 10 days ago', 'Admin', 'Wipro', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-006', 'Jack Dorsey', 'Given access 10 days ago', 'Manager', 'D & D Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-007', 'Mark zuckerberg', 'Given access 10 days ago', 'Admin', 'Wipro', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-008', 'Bill Gates', 'Given access 10 days ago', 'Manager', 'Wipro', '04/07/2019 04:16 PM', 'Sidebar Update'),
    userTable('user-009', 'Mark zuckerberg', 'Given access 10 days ago', 'Admin', 'D & D Labs', '04/07/2019 04:16 PM', 'Sidebar Update'),
];

// User Detail table List end

// Thing Detail Table List start
const thingTable_header = [
    'Thing Id', 'Thing Name', 'Description', 'Type', 'Unit', 'Min', 'Max'
]
function thingTable(thing_id, thing_name, desc, type, unit, min, max) {
    return { thing_id, thing_name, desc, type, unit, min, max };
}
const thingTable_rows = [
    thingTable('thing-001', 'Gps', 'GPS', 'Analog', 'Kg', 10, 30),
    thingTable('thing-002', 'Sensor', 'Sensor', 'Analog', 'Kg', 10, 30),
    thingTable('thing-003', 'Camera', 'Camera', 'Analog', 'Kg', 10, 30),
    thingTable('thing-004', 'Gps', 'Gps', 'Analog', 'Kg', 10, 30),
    thingTable('thing-005', 'Camera', 'Camera', 'Analog', 'Kg', 10, 30),
    thingTable('thing-006', 'Gps', 'GPS', 'Analog', 'Kg', 10, 30),
    thingTable('thing-007', 'Sensor', 'Sensor', 'Analog', 'Kg', 10, 30),
    thingTable('thing-008', 'Camera', 'Camera', 'Analog', 'Kg', 10, 30),
    thingTable('thing-009', 'Gps', 'Gps', 'Analog', 'Kg', 10, 30),
];
// Thing Detail Table List end

// Project Detail Table List start
const proTable_header = [
    'Project Id', 'Project Name', 'Description', 'Created On', 'Last Updated', 'Actions'
];
function proTable(pro_id, pro_name, pro_desc, created, updated) {
    return { pro_id, pro_name, pro_desc, created, updated };
}
const proTable_rows = [
    proTable('Pro-001', 'Epsum Things', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-002', 'D & D Labs', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-003', 'Monkey Ropes', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-004', 'D & D Labs', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-005', 'Baby Dolphin', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-006', 'Epsum Things', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-007', 'D & D Labs', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-008', 'Monkey Ropes', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
    proTable('Pro-009', 'D & D Labs', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ', '14/07/2019 08:35:23 PM', '14/07/2019 08:35:23 PM'),
];
// Project Detail Table List end

// Project Detail Table List start
const schema_header = [
    'Schema Id', 'Schema Name', 'Added On', 'Action'
];
function schemaTable(schema_id, schema_name, addedon) {
    return { schema_id, schema_name, addedon };
}
const schemaTable_rows = [
    schemaTable('schema-001', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-002', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-003', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-004', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-005', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-006', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-007', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-008', 'schema name', '14/07/2019 08:35:23 PM'),
    schemaTable('schema-009', 'schema name', '14/07/2019 08:35:23 PM'),
];
// Project Detail Table List end

// Alarm table start
function alarmsTable(alarm_name) {
    return { alarm_name }
}
const alarmsTable_rows = [
    alarmsTable('Alarm - 1'), alarmsTable('Alarm - 2'), alarmsTable('Alarm - 3'), alarmsTable('Alarm - 4'),
    alarmsTable('Alarm - 5'), alarmsTable('Alarm - 6'), alarmsTable('Alarm - 7'), alarmsTable('Alarm - 8'),
    alarmsTable('Alarm - 9'),
]
// Alarm table end

// Project shortcut table start
function ProjectshortcutTable(project_id, project_path) {
    return { project_id, project_path }
}
const ProjectshortcutTable_rows = [
    ProjectshortcutTable('things_001', 'c://epsumlabs/projects/things/things_001'),
    ProjectshortcutTable('things_002', 'c://epsumlabs/projects/things/things_002'),
    ProjectshortcutTable('things_003', 'c://epsumlabs/projects/things/things_003'),
    ProjectshortcutTable('things_004', 'c://epsumlabs/projects/things/things_004'),
    ProjectshortcutTable('things_005', 'c://epsumlabs/projects/things/things_005'),
    ProjectshortcutTable('things_006', 'c://epsumlabs/projects/things/things_006'),
    ProjectshortcutTable('things_007', 'c://epsumlabs/projects/things/things_007'),
    ProjectshortcutTable('things_008', 'c://epsumlabs/projects/things/things_008'),
    ProjectshortcutTable('things_009', 'c://epsumlabs/projects/things/things_009'),
    ProjectshortcutTable('things_010', 'c://epsumlabs/projects/things/things_010'),
]
// Project shortcut table end

// youtube video start
function videoTable(video_link, video_desc) {
    return { video_link, video_desc }
}
const videoTable_rows = [
    videoTable('https://youtu.be/7QYDPFkbeXM', 'Keiri - Accounting Software (introductory Video)'),
    videoTable('https://youtu.be/IiGnmrfS6Ko', 'Smart Garbage Management'),
    videoTable('https://youtu.be/-8sGs0sX59I', 'Epsum ERP Software Demo'),
    videoTable('https://youtu.be/pgGtNgk9nyo', 'Keiri Accounting Software'),
    videoTable('https://youtu.be/d-VJZoQN7oc', 'Epsum Office ERP'),
    videoTable('https://youtu.be/xBLN2OhjclA', 'Epsum Software Services.'),
    videoTable('https://youtu.be/vMAT9mwZM64', 'Journey of Epsum Labs "How to Now"!!!'),
];
// youtube video end

// Lat & Lng start
function latTable(latitude, longitude) {
    return { latitude, longitude }
}
const latTable_rows = [
    latTable('20.323607', '84.821914'),
    latTable('20.3242116', '85.821081'),
    latTable('40.36', '66.54'),
    latTable('35.45', '67.45'),
    latTable('89.45', '56.34'),
];
// Lat & Lng end


// Apps Table List start
const appTable_header = [
    'App Id', 'App Name', 'Added On', 'Action'
];
function appTable(app_id, app_name, addedon) {
    return { app_id, app_name, addedon };
}
const appTable_rows = [
    appTable('App-001', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-002', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-003', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-004', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-005', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-006', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-007', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-008', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-009', 'App name', '14/07/2019 08:35:23 PM'),
    appTable('App-010', 'App name', '14/07/2019 08:35:23 PM'),
];
// Apps Table List end

// country List start
const country = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
]
// country List end


const gender = [
    'Select Gender', 'Male', 'Female', 'Others'
]

const schemaa = [
    "Select schema", 'schema-001', 'schema-002', 'schema-003', 'schema-004'
]

const gpstypes = ["Select format", "NMEA", "LAT_LONG"]


export { ProjectshortcutTable_rows, latTable_rows, videoTable_rows, alarmsTable_rows, appTable_header, appTable_rows, schema_header, schemaTable_rows, gpstypes, schemaa, units, param_types, thingTable_header, thingTable_rows, proTable_header, proTable_rows, gender, country, userTable_header, userTable_rows, activityLogs_header, activityLogs_rows }

export const features = [
    {
        name: "Camera",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true,
    },
    {
        name: "Alarms",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },
    {
        name: "Things",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },
    {
        name: "Trends",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },
    {
        name: "Project",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },

    {
        name: "Webhook",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },
    {
        name: "Infrared",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },

    {
        name: "Process",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },
    {
        name: "Analytics",
        create: true,
        write: true,
        control: true,
        delete: true,
        read: true
    },




]

export const userrole = [
    {
        no: "1",
        name: "Manager"
    },
    {
        no: "2",
        name: "Project head"
    },
    {
        no: "3",
        name: "Sub ordinates"
    }
]

export const users = [
    {
        no: "1",
        name: "Anna"
    },
    {
        no: "2",
        name: "Diree"
    }
]


export const distance_reports = {
    "status": "OK",
    "code": 200,
    "data": [
        {
            "Vehicle": "HR79A0942",
            "01/04/2023": "2",
            "02/04/2023": "8",
            "03/04/2023": "9",
            "04/04/2023": "2",
            "05/04/2023": "8",
            "06/04/2023": "2",
            "07/04/2023": "11",
            "08/04/2023": "17",
            "09/04/2023": "2",
            "10/04/2023": "23",
            "11/04/2023": "27",
            "12/04/2023": "11",
            "13/04/2023": "18",
            "14/04/2023": "18",
            "15/04/2023": "6",
            "16/04/2023": "18",
            "17/04/2023": "35",
            "18/04/2023": "25",
            "19/04/2023": "27",
            "20/04/2023": "27",
            "21/04/2023": "25",
            "22/04/2023": "25",
            "23/04/2023": "4",
            "24/04/2023": "23",
            "25/04/2023": "7",
            "26/04/2023": "9",
            "27/04/2023": "20",
            "28/04/2023": "7",
            "29/04/2023": "29",
            "30/04/2023": "20",
            "01/05/2023": "5",
            "TotalKm": "470"
        },
        {
            "Vehicle": "JCB",
            "01/04/2023": "2",
            "02/04/2023": "2",
            "03/04/2023": "7",
            "04/04/2023": "8",
            "05/04/2023": "33",
            "06/04/2023": "5",
            "07/04/2023": "20",
            "08/04/2023": "24",
            "09/04/2023": "16",
            "10/04/2023": "44",
            "11/04/2023": "5",
            "12/04/2023": "0",
            "13/04/2023": "0",
            "14/04/2023": "0",
            "15/04/2023": "0",
            "16/04/2023": "0",
            "17/04/2023": "0",
            "18/04/2023": "0",
            "19/04/2023": "0",
            "20/04/2023": "0",
            "21/04/2023": "0",
            "22/04/2023": "0",
            "23/04/2023": "0",
            "24/04/2023": "0",
            "25/04/2023": "0",
            "26/04/2023": "0",
            "27/04/2023": "0",
            "28/04/2023": "0",
            "29/04/2023": "0",
            "30/04/2023": "0",
            "01/05/2023": "0",
            "TotalKm": "166"
        },
        {
            "Vehicle": "JCB-220",
            "01/04/2023": "0",
            "02/04/2023": "1",
            "03/04/2023": "1",
            "04/04/2023": "5",
            "05/04/2023": "11",
            "06/04/2023": "0",
            "07/04/2023": "9",
            "08/04/2023": "5",
            "09/04/2023": "0",
            "10/04/2023": "10",
            "11/04/2023": "0",
            "12/04/2023": "0",
            "13/04/2023": "0",
            "14/04/2023": "0",
            "15/04/2023": "0",
            "16/04/2023": "65",
            "17/04/2023": "54",
            "18/04/2023": "0",
            "19/04/2023": "0",
            "20/04/2023": "0",
            "21/04/2023": "0",
            "22/04/2023": "0",
            "23/04/2023": "0",
            "24/04/2023": "0",
            "25/04/2023": "0",
            "26/04/2023": "0",
            "27/04/2023": "0",
            "28/04/2023": "0",
            "29/04/2023": "0",
            "30/04/2023": "0",
            "01/05/2023": "0",
            "TotalKm": "161"
        }
    ]
}