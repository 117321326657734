import { baseurl } from '../constants/api'
import { ACCESS_TOKEN, LOGIN, THING_ALARM_LIST } from '../redux/types'
import { setUserDataToStorage } from '../library/utils'
import { NOTIFICATION_COLOUR } from '../constants/api'
import { setFlashNotification } from '../redux/action/uiAction';
import { getProfileData } from '../constants/api'
import { PROFILE_DATA } from '../redux/types'
const user_auth = '/user/auth/'
const user_verify = '/user/verify/'

export function getAccessToken(email, password, history) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Authenticate " + btoa(email + ":" + btoa(password)));
    return function (dispatch) {
        fetch(baseurl + user_auth, {
            method: 'POST',
            headers: myHeaders
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setUserDataToStorage(result.data.access_token)
                    dispatch({ type: ACCESS_TOKEN, payload: result.data.access_token });
                    dispatch({
                        type: LOGIN,
                        payload: {
                            access_token: 'Bearer ' + result.data.access_token,
                            username: email,
                            name: 'PRE_USER_FETCH',
                            timezone: "Asia/Kolkata"
                        }
                    });
                    dispatch(setFlashNotification(result.msg, NOTIFICATION_COLOUR.SUCCESS))
                    // setTimeout(() => dispatch(unsetFlashNotification()), 4000)
                    history.push("/home")
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => alert('Wrong Credentials', error))
    }
}

export function verifyUser(accessToken) {
    console.log('Acess_Token', accessToken)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Bearer ' + accessToken);
    return function (dispatch) {
        fetch(baseurl + user_verify, {
            method: 'GET',
            headers: myHeaders
        })
            .then(response => response.json())
            .then(result => { console.log(result) })
            .catch(error => console.log('error', error));
    }
}

export function getProfileDetail(accessToken) {
    return function (dispatch) {
        dispatch({ type: PROFILE_DATA, payload: {} })
        getProfileData(accessToken,
            (response) => {
                dispatch({ type: PROFILE_DATA, payload: response.data })
            },
            (responseFailed) => {
                dispatch(setFlashNotification(responseFailed.msg, 'error'))
            },
            (responseError) => {
                dispatch(setFlashNotification(responseError.msg, 'error'))
            })
    }

}

export function setThingAlarmList(list = []) {
    return function (dispatch) {
        dispatch({ type: THING_ALARM_LIST, payload: list })
    }
}