import {
    GROUP_LIST,
    GROUP_ROLES_LIST,
    LOGOUT
} from '../types';

const initialState = {

    groupList: [],
    groupRolesList: [],
    groupCount: 0
}

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GROUP_LIST:
            return {
                ...state,
                groupList: action.payload.data,
                groupCount: action.payload.count
            }

        case GROUP_ROLES_LIST:
            return {
                ...state,
                groupRolesList: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                groupList: [],
                groupRolesList: [],
                groupCount: 0
            }
        default:
            return state;
    }

};

export default groupReducer;