import React, { Component } from 'react';
import {
  ResponsiveContainer, ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const data = [
  {
    name: '2014', min: 590, max: 800, avg: 1400,
  },
  {
    name: '2015', min: 868, max: 967, avg: 1506,
  },
  {
    name: '2016', min: 1397, max: 1098, avg: 989,
  },
  {
    name: '2017', min: 1480, max: 1200, avg: 1228,
  },
  {
    name: '2018', min: 1520, max: 1108, avg: 1100,
  },
  {
    name: '2019', min: 1400, max: 680, avg: 1700,
  },
];

export default class Linechart extends Component {

  render() {
    return (
      <div style={{ width: '100%', height: 350 }}>
        <ResponsiveContainer>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 20, left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey="avg" fill="#8884d8" stroke="#8884d8" />
            <Bar dataKey="max" barSize={20} fill="#413ea0" />
            <Line type="monotone" dataKey="min" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
