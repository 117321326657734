import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
// import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import LinkIcon from "@material-ui/icons/Link";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import Grid from "@material-ui/core/Grid";
import Link from "react-router-dom/Link";
import AlarmFeed from "../Components/alarmFeed";
import {
  baseurl,
  getUserWiseThingList,
  listNProjects,
  NOTIFICATION_COLOUR,
  ws_baseurl,
} from "../constants/api";
import { connect } from "react-redux";
import { LOGOUT, PROJECTS } from "../redux/types";
import { MenuItem, Menu } from "@material-ui/core";
import FlashNotification from "../Components/modal/flashNotification";
import { setFlashNotification } from "../redux/action/uiAction";
import { GamesTwoTone } from "@material-ui/icons";

import { getProfileDetail, setThingAlarmList } from "../panel/loginAction";

const mainTheme = createMuiTheme({
  palette: {
    type: "light",
  },
});

// const styles = {
//   paper: {
//     background: "#fff",
//     marginTop:'-14px',
//   }
// };

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    height: `40`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: `100%`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {
    backgroundColor: "#333",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  zeroPaddingContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
}));

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  nestedd: {
    paddingLeft: theme.spacing(5),
  },
  ne_stedd: {
    paddingLeft: theme.spacing(7),
  },
}));

function DashboardRoot(props) {
  const classes = useStyles();
  const [showAlert, changeShowAlertState] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [open_project_actions, set_open_project_actions] =
    React.useState(false);
  const [projects_expansion, setProjectsExpansion] = React.useState([]);
  const [projects, setProjects] = React.useState([]);

  function handleProjectExpansion(index) {
    let expansion_state = [...projects_expansion];
    expansion_state[index] = !expansion_state[index];
    setProjectsExpansion(expansion_state);
  }

  function changeState() {
    changeShowAlertState(!showAlert);
  }

  const history = useHistory();

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
    setOpenn(false);
    set_Open(false);
    setoPen(false);
  }
  const classess = useStyle();
  const [, setOpenn] = React.useState(false);
  const [open_n, set_Open] = React.useState(false);
  const [open_nn, set_Openn] = React.useState(false);
  const [expand, set_expand] = React.useState(false);
  const [gps_reports, setGps_reports] = React.useState(false);
  const [groups_status, setGroups_status] = React.useState(false);
  const [user_thing_list, setUser_thing_list] = React.useState([]);
  const [id, setId] = React.useState(false);
  const [] = React.useState(false);
  const [, setoPen] = React.useState(false);

  // const [state, setState] = React.useState({
  //   open: false,
  //   vertical: 'top',
  //   horizontal: 'right',
  //   snack_message: ''
  // });

  // const { vertical, horizontal, open_snack, snack_message } = state;

  function handle_Click() {
    set_Open(!open_n);
  }
  function handle_Clicks() {
    set_Openn(!open_nn);
  }
  function account_tab() {
    set_expand(!expand);
  }

  function triggerAlarm(title, message) {
    new window.Notification(title, {
      body: message,
    });
    // setState({ open_snack: true, snack_message: message, ...state })
  }
  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };

  const checkExistAlarm = (list, alarm) => {
    var status = false;

    for (let index = 0; index < list.length; index++) {
      if (list[index].name == alarm.name) {
        status = index;
        break;
      }
    }
    return status;
  };
  
  useEffect(() => {
    props.getProfileDetail(props.userData.access_token);
    if (Object.keys(props.userData).length === 0) {
      alert("Authentication Failed, Please Login Again");
      history.push("/login");
    }
    // GetId(props.userData.access_token);

    var myList = [];

    getUserWiseThingList(
      props.userData.access_token,
      (success) => {
        console.log("sucess", success);

        listNProjects(
          props.userData.access_token,
          props.userData.username,
          5,
          (successResponse) => {
            setProjects(successResponse.data.data);

            props.populate_project(successResponse.data);
            if (window.Notification.permission !== "granted") {
              window.Notification.requestPermission();
            }

            const ws = new WebSocket(`${ws_baseurl}/alarm`);
            ws.onmessage = (event) => {
              const json_message = JSON.parse(event.data);
              const json_message_data = json_message.message;
              console.log("json_message_data", json_message_data);
              if (
                typeof json_message.message == "object" &&
                success.data.things.length > 0
              ) {
                success.data.things.map((item, index) => {
                  console.log("itemsssssss", success);
                  if (json_message_data) {
                    var my_index = checkExistAlarm(myList, json_message_data);
                    console.log("my_index", my_index);
                    if (my_index) {
                      myList[my_index] = {
                        project_id: item.project_id ?? "",
                        project_name: item.project_name ?? "",
                        thing_name: item.thing_name,
                        ...json_message_data,
                      };
                    } else {
                      myList.push({
                        project_id: item.project_id ?? "",
                        project_name: item.project_name ?? "",
                        thing_name: item.thing_name,
                        thing_id: item.thing_id,
                        pin: item.pin,
                        ...json_message_data,
                      });
                    }
                  }
                });
                // console.log("myList", myList)
                props.setThingAlarmList(myList);
              }
              if (typeof json_message.message.alarm_type !== "undefined")
                triggerAlarm(
                  "Title",
                  `${json_message.message.alarm_type.toUpperCase()}\nPin "${
                    json_message.message.name
                  }" reached value "${json_message.message.value}"`
                );
            };
            ws.onopen = (event) => {
              console.log(event);
            };
          },
          () => {},
          () => {}
        );
      },
      (failure) => {
        alert(failure.msg);
      },
      (error) => {
        alert(error);
      }
    );
    console.log("myList", myList);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log("props", id);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="navbar_background">
          {/* <IconButton
            className={clsx(classes.menuButton, {
              [classes.hide]: !open,
            })}
            style={{color:'#fff'}} onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerClose}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: !open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/home">
            <Typography variant="h6" noWrap style={{ color: "#fff" }}>
              <span className="logo_name_col">EpsumThings</span>
            </Typography>
          </Link>
          &nbsp;&nbsp;&nbsp;
          {/* <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={open_snack}
            onClose={handleClose}
            autoHideDuration={6000}
            message={snack_message}
          /> */}
          {/* <ThemeSwitcher
            cssSelector="body"
            switcherColor="#ffffff"
            darkColor="#282c34"
            lightColor="#ffffff"
            lightTextColor="#272b33"
            darkTextColor="#ffffff"
        /> */}
          <div className="col align-self-end top-names">
            <Grid container justifyContent="flex-end">
              {/* <div className="dropdown">
                <a style={{ background: 'transparent', outline: 'none', color: '#fff' }} href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Notification />
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" >
                  <a className="dropdown-item" href="/">Notification 1</a>
                  <a className="dropdown-item" href="/">Notification 1</a>
                  <a className="dropdown-item" href="/">Notification 1</a>
                </div>
              </div> */}
              <IconButton
                color="inherit"
                aria-label="Alarm Notifications"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: !open,
                })}
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Link to="/profile" className="sidebar_link">
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Link>

                <Link
                  to={"/login"}
                  onClick={() => {
                    props.setFlashNotification(
                      "Logged Out Successfully",
                      NOTIFICATION_COLOUR.SUCCESS
                    );
                    props.logout();
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("state");
                  }}
                >
                  <MenuItem>Logout</MenuItem>
                </Link>
              </Menu>
              &nbsp;&nbsp;
              {/* <a href="/">
                <Avatar />
              </a> */}
            </Grid>
          </div>
          <Typography>{props.name}</Typography>
          {/* <ThemeSwitcher
            cssSelector="body"
            switcherColor="#ffffff"
            darkColor="#282c34"
            lightColor="#ffffff"
            lightTextColor="#272b33"
            darkTextColor="#ffffff"
        /> */}
        </Toolbar>
      </AppBar>
      <MuiThemeProvider theme={mainTheme}>
        <Drawer
          classes={{ paper: classes.paper }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            {/* <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton> */}
          </div>
          <List className="list-bg">
            <Link to="/home">
              <ListItem button title="DASHBOARD">
                <ListItemIcon>
                  <i className="material-icons listitemtext">home</i>
                </ListItemIcon>
                <ListItemText primary="Home" className="listitemtext" />
              </ListItem>
            </Link>
            <Divider />

            <ListItem
              button
              onClick={() => {
                setGroups_status(!groups_status);
              }}
              title="Master"
            >
              <ListItemIcon>
                <i className="material-icons listitemtext">group</i>
              </ListItemIcon>
              <ListItemText primary="Master" className="listitemtext" />
              {groups_status ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={groups_status} timeout="auto" unmountOnExit>
              <Link to="/groups">
                <ListItem
                  button
                  title="All Groups"
                  className={classess.nestedd}
                >
                  <ListItemIcon>
                    <i className="material-icons listitemtext">group_add</i>
                  </ListItemIcon>
                  <ListItemText primary="All Groups" className="listitemtext" />
                </ListItem>
              </Link>
              <Divider />

              <Link to="/assign_user_group">
                <ListItem
                  button
                  title="Assign Group"
                  className={classess.nestedd}
                >
                  <ListItemIcon>
                    <i className="material-icons listitemtext">
                      assignment_ind
                    </i>
                  </ListItemIcon>
                  <ListItemText
                    primary="Assign Group"
                    className="listitemtext"
                  />
                </ListItem>
              </Link>

              {/* <Link to="/bulk_insert">
                <ListItem button title="Bulk Insert" className={classess.nestedd}>
                  <ListItemIcon>
                    <i className="material-icons listitemtext">insert_chart</i>
                  </ListItemIcon>
                  <ListItemText primary="Bulk Insert" className="listitemtext" />
                </ListItem>
              </Link> */}

              <Link to="/all_properties">
                <ListItem
                  button
                  title="All Properties"
                  className={classess.nestedd}
                >
                  <ListItemIcon>
                    <i className="material-icons listitemtext">art_track</i>
                  </ListItemIcon>
                  <ListItemText
                    primary="All Properties"
                    className="listitemtext"
                  />
                </ListItem>
              </Link>
            </Collapse>

            {/* <ListItem button onClick={() => { setGps_reports(!gps_reports) }} title="GPS Reports">
              <ListItemIcon>
                <i className="material-icons listitemtext">location_on</i>
              </ListItemIcon>
              <ListItemText primary="GPS Reports" className="listitemtext" />
              {
                gps_reports ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={gps_reports} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/advance_gps_reports">
                  <ListItem button className={classess.nestedd} title="Advance GPS Reports">
                    <ListItemIcon >
                      <i className="material-icons listitemtext">gps_fixed</i>
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: -10 }} primary="Advance Reports" className="listitemtext" />
                  </ListItem>
                </Link>
              </List>
              <List component="div" disablePadding>
                <Link to="/distance_report">
                  <ListItem button className={classess.nestedd} title="Distance Reports">
                    <ListItemIcon >
                      <i className="material-icons listitemtext">add_location</i>
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: -10 }} primary="Distance Reports" className="listitemtext" />
                  </ListItem>
                </Link>
              </List>
              <List component="div" disablePadding>
                <Link to="/status_report">
                  <ListItem button className={classess.nestedd} title="Status Reports">
                    <ListItemIcon >
                      <i className="material-icons listitemtext">insert_chart</i>
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: -10 }} primary="Status Reports" className="listitemtext" />
                  </ListItem>
                </Link>
              </List>
              <List component="div" disablePadding>
                <Link to="/travel_summary_report">
                  <ListItem button className={classess.nestedd} title="Travel Summary Reports">
                    <ListItemIcon >
                      <i className="material-icons listitemtext">directions_car</i>
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: -10 }} primary="Travel Reports" className="listitemtext" />
                  </ListItem>
                </Link>
              </List>
            </Collapse> */}

            {/* <Link to="/dashboard/projects"> */}
            <ListItem button onClick={handle_Click} title="Projects">
              <ListItemIcon>
                <i className="material-icons listitemtext">account_tree</i>
              </ListItemIcon>
              <ListItemText primary="Projects" className="listitemtext" />
              {open_n ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {/* </Link> */}

            <Collapse in={open_n} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/projects">
                  <ListItem button className={classess.nestedd} title="ML">
                    <ListItemIcon>
                      <i className="material-icons listitemtext">bookmarks</i>
                    </ListItemIcon>
                    <ListItemText
                      primary="All Projects"
                      className="listitemtext"
                    />
                  </ListItem>
                </Link>
                {/* <Link to="/schema">
                    <ListItem button className={classess.nestedd} title="ML">
                        <ListItemIcon>
                          <i className="material-icons listitemtext">code</i>
                        </ListItemIcon>
                        <ListItemText primary="Schema" className="listitemtext" />
                    </ListItem>
                    </Link> */}

                {/* <Link to="/dashboard/projects/project-1"> */}

                {projects.map((project, i) => (
                  <React.Fragment key={i}>
                    <ListItem
                      button
                      onClick={(event) => handleProjectExpansion(i)}
                      className={classess.nestedd}
                      title="Projects"
                    >
                      <ListItemIcon>
                        <i className="material-icons listitemtext">
                          bookmark_border
                        </i>
                      </ListItemIcon>
                      <ListItemText
                        primary={project.project_name}
                        className="listitemtext"
                      />
                      {projects_expansion[i] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse
                      in={projects_expansion[i]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link to={`/projects/${project.project_id}`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Project Detail"
                          >
                            <ListItemIcon>
                              <i className="material-icons listitemtext">
                                book
                              </i>
                            </ListItemIcon>
                            <ListItemText
                              primary="Project Detail"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/things`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Things"
                          >
                            <ListItemIcon>
                              <SettingsInputComponentIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Things"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/webhooks`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="WebHooks"
                          >
                            <ListItemIcon>
                              <LinkIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="WebHooks"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/camera`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Camera"
                          >
                            <ListItemIcon>
                              <LinkedCameraIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Camera"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link
                          to={`/projects/${project.project_id}/imagestream`}
                        >
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Image stream"
                          >
                            <ListItemIcon>
                              <PhotoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Image Stream"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/webscada`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Webscada"
                          >
                            <ListItemIcon>
                              <i className="material-icons listitemtext">
                                scatter_plot
                              </i>
                            </ListItemIcon>
                            <ListItemText
                              primary="Webscada"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/irremotes`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="IRRemote"
                          >
                            <ListItemIcon>
                              <GamesTwoTone />
                            </ListItemIcon>
                            <ListItemText
                              primary="Infra Red"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        {/* <Link to={`/projects/${project.project_id}/process`}>
                          <ListItem button className={classess.ne_stedd} title="Process">
                            <ListItemIcon>
                              <BubbleChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Process" className="listitemtext" />
                          </ListItem>
                        </Link> */}
                        <Link to={`/projects/${project.project_id}/alarms`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Alarms"
                          >
                            <ListItemIcon>
                              <i className="material-icons listitemtext">
                                alarm_on
                              </i>
                            </ListItemIcon>
                            <ListItemText
                              primary="Alarms"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/trends`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Trend"
                          >
                            <ListItemIcon>
                              <i className="material-icons listitemtext">
                                trending_up
                              </i>
                            </ListItemIcon>
                            <ListItemText
                              primary="Trend"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        <Link to={`/projects/${project.project_id}/analytics`}>
                          <ListItem
                            button
                            className={classess.ne_stedd}
                            title="Analytics"
                          >
                            <ListItemIcon>
                              <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Analytics"
                              className="listitemtext"
                            />
                          </ListItem>
                        </Link>
                        {/* <Link to="">
                            <ListItem button className={classess.ne_stedd} title="ML">
                              <ListItemIcon>
                                <i className="material-icons listitemtext">gesture</i>
                              </ListItemIcon>
                              <ListItemText primary="ML" className="listitemtext" />
                            </ListItem>
                          </Link> */}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
            <Divider />
            {/* <a href="http://things.epsumlabs.com/api/world/docs">
              <ListItem button title="API DOCS">
                <ListItemIcon>
                  <i className="material-icons listitemtext">settings_input_composite</i>
                </ListItemIcon>
                <ListItemText primary="Api Docs" className="listitemtext" />
              </ListItem>
            </a> */}

            {props.profileData.organisation !== undefined &&
              props.profileData.organisation.length >= 1 && (
                <>
                  <ListItem button onClick={handle_Clicks} title="Management">
                    <ListItemIcon>
                      <i className="material-icons listitemtext">card_travel</i>
                    </ListItemIcon>
                    <ListItemText
                      primary="Management"
                      className="listitemtext"
                    />
                    {open_nn ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={open_nn} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link to="/userrole">
                        <ListItem
                          button
                          className={classess.nestedd}
                          title="User Role"
                        >
                          <ListItemIcon>
                            <i className="material-icons listitemtext">
                              check_circle_outline
                            </i>
                          </ListItemIcon>
                          <ListItemText
                            primary="User Role"
                            className="listitemtext"
                          />
                        </ListItem>
                      </Link>
                    </List>
                    <List component="div" disablePadding>
                      <Link to="/users">
                        <ListItem
                          button
                          className={classess.nestedd}
                          title="Users"
                        >
                          <ListItemIcon>
                            <i className="material-icons listitemtext">
                              {" "}
                              person_add
                            </i>
                          </ListItemIcon>
                          <ListItemText
                            primary="Users"
                            className="listitemtext"
                          />
                        </ListItem>
                      </Link>
                    </List>
                    <List component="div" disablePadding>
                      <Link to="/group">
                        <ListItem
                          button
                          className={classess.nestedd}
                          title="Groups"
                        >
                          <ListItemIcon>
                            <i className="material-icons listitemtext">
                              {" "}
                              groups
                            </i>
                          </ListItemIcon>
                          <ListItemText
                            primary="Groups"
                            className="listitemtext"
                          />
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                </>
              )}

            <Divider />
            <Link to="/dashboard" className="sidebar_link">
              <ListItem button title="Dashboard">
                <ListItemIcon>
                  <i className="material-icons listitemtext">dashboard</i>
                </ListItemIcon>
                <ListItemText primary="Dashboard" className="listitemtext" />
              </ListItem>
            </Link>
            <Divider />

            <Link to="/logs">
              <ListItem button title="LOGS">
                <ListItemIcon>
                  <i className="material-icons listitemtext">list_alt</i>
                </ListItemIcon>
                <ListItemText primary="Logs" className="listitemtext" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/apps">
              <ListItem button title="Apps">
                <ListItemIcon>
                  <i className="material-icons listitemtext">widgets</i>
                </ListItemIcon>
                <ListItemText primary="Apps" className="listitemtext" />
              </ListItem>
            </Link>
            <Divider />
            {/* --------tab for account expand------------ */}
            <ListItem button onClick={account_tab} title="Account">
              <ListItemIcon>
                <i className="material-icons listitemtext">manage_accounts</i>
              </ListItemIcon>
              <ListItemText primary="Account" className="listitemtext" />
              {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/profile">
                  <ListItem button className={classess.nestedd} title="PROFILE">
                    <ListItemIcon>
                      <i className="material-icons listitemtext">
                        account_circle
                      </i>
                    </ListItemIcon>
                    <ListItemText primary="Profile" className="listitemtext" />
                  </ListItem>
                </Link>

                {/* <Link to="/billingaccount">
                  <ListItem button className={classess.nestedd} title="PROFILE">
                    <ListItemIcon >
                      <i className="material-icons listitemtext">account_balance_wallet</i>
                    </ListItemIcon>
                    <ListItemText primary="Billing" className="listitemtext" />
                  </ListItem>
                </Link> */}

                {/* <Link to="/package">
                  <ListItem button className={classess.nestedd} title="Package">
                    <ListItemIcon>
                      <i className="material-icons listitemtext">inventory</i>
                    </ListItemIcon>
                    <ListItemText primary="Package" className="listitemtext" />
                  </ListItem>
                </Link> */}

                {/* <Link to="/integration">
                  <ListItem button className={classess.nestedd} title="integration">
                    <ListItemIcon>
                      <i className="material-icons listitemtext">gamepad</i>
                    </ListItemIcon>
                    <ListItemText primary="Integration" className="listitemtext integration" />
                  </ListItem>
                </Link> */}
              </List>
            </Collapse>

            <Divider />
            {/* --------end of tab for account expand------------ */}

            {/* <Link to="/manufacturing">
              <ListItem button title="Manufacturing">
                <ListItemIcon>
                  <i className="material-icons listitemtext">poll</i>
                </ListItemIcon>
                <ListItemText primary="Manufacturing" className="listitemtext" />
              </ListItem>
            </Link> */}
            <Divider />
            {/* <Link to="/upgrade">
              <ListItem button title="Manufacturing">
                <ListItemIcon>
                  <i className="material-icons listitemtext">polymer</i>
                </ListItemIcon>
                <ListItemText primary="Upgrade" className="listitemtext" />
              </ListItem>
            </Link> */}

            {/* <Link to={'/allcomponents'} >
              <ListItem button title="LOG OUT">
                <ListItemIcon>
                  <i className="material-icons listitemtext">exit_to_app</i>
                </ListItemIcon>
                <ListItemText primary="All Components" className="listitemtext" />
              </ListItem>
            </Link> */}

            <Link
              to={"/login"}
              onClick={() => {
                props.logout();
                localStorage.removeItem("accessToken");
                localStorage.removeItem("state");
              }}
            >
              <ListItem button title="LOG OUT">
                <ListItemIcon>
                  <i className="material-icons listitemtext">exit_to_app</i>
                </ListItemIcon>
                <ListItemText primary="Logout" className="listitemtext" />
              </ListItem>
            </Link>
            <Divider />
            {/* <Link to="/All-Components">
              <ListItem button title="LOG OUT">
                <ListItemIcon >
                  <i className="material-icons listitemtext">how_to_vote</i>
                </ListItemIcon>
                <ListItemText primary="All Components" className="listitemtext" />
              </ListItem>
            </Link> 
            <Divider /> */}
          </List>
        </Drawer>
      </MuiThemeProvider>
      <AlarmFeed />
      <FlashNotification
        message={"SuccessFully Deleted"}
        flashColor={"success"}
      />

      {props.zeroPad ? (
        <main
          className={clsx(classes.zeroPaddingContent, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          {props.children}
        </main>
      ) : (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          {props.children}
        </main>
      )}
      {/* } */}
    </div>
  );
}

const mapPropsToState = (store) => {
  return {
    username: store.username,
    token: store.access_token,
    name: store.name,
    profileData: store.userReducer.profileData,
    userData: store.userReducer.userData,
    thingAlarmList: store.userReducer.thingAlarmList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: LOGOUT }),
    populate_project: (projects) =>
      dispatch({ type: PROJECTS, payload: { projects: projects } }),
    setFlashNotification: (msg, color) =>
      dispatch(setFlashNotification(msg, color)),
    getProfileDetail: (accessToken) => dispatch(getProfileDetail(accessToken)),
    setThingAlarmList: (list) => dispatch(setThingAlarmList(list)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(DashboardRoot);
