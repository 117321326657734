import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Logo from '../images/things-logo.png';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="landing_footer" id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <a className="brand-navbar" href="/"><img src={Logo} alt="Responsive image" style={{ height: "140px" }} /></a>
                            </div>
                            <div className="col-md-3">
                            <div ClassName ="footer-contents">
                            <h3>Reach Us</h3>
							<ul ClassName ="footer-contacts">
								<li><span><a href="">+91 7377073771</a></span></li>
								<li><span><a href="">support@epsumlabs.com</a></span></li>
								<li><span><a href="https://www.epsumlabs.com/">www.epsumlabs.com</a></span></li>
							</ul>
						</div>
                            </div>
                            <div className="col-md-3">
                                <h3>Quick Link</h3>
                                <ul>
                                    <li><Link to="/"> Home </Link></li>
                                    <li><Link to="#"> Community </Link></li>
                                    <li><Link to="#"> Contact Us </Link></li>
                                    <li><Link to="#"> Developers </Link></li>
                                    {/* <li> <Link to="/terms&conditions"> Terms & Conditions </Link> </li> */}
                                    <li> <Link to="/privacypolicy"> Privacy Policy </Link> </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h3> Social Media</h3>
                                <div className="">

                                    <ul className="social">
                                        <li className="facebooks"> <a href="https://www.facebook.com/epsumlabs"> <i className="fab fa-facebook-f">   </i> </a> </li>
                                        <li className="twitters"> <a href="https://twitter.com/epsumlabs"> <i className="fab fa-twitter">   </i> </a> </li>
                                        <li className="instas"> <a href="https://www.instagram.com/epsumlabs"> <i className="fab fa-instagram"></i> </a> </li>
                                        <li className="linkedins"> <a href="https://www.linkedin.com/company/epsumlabs"> <i className="fab fa-linkedin-in"></i> </a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <h5 className="footer-para text-center">© 2021 <span className="logo-col">E</span>psum <span className="logo-col">L</span>abs Pvt. Ltd. All Rights Reserved</h5>
                    </div>
                </div>
            </footer>
        );
    }
}