

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default class Navigation extends Component {

  state = {
    expanded: false,
    show: false,
    name: '',
    email: '',
    company: '',
    contactNo: '',
    subject: '',
    message: '',
    designation: '',
    partnerStatus: false
  };

  handleAboutClick = () => {
    this.props.scrollToAbout();
  }
  handleServiceClick = () => {
    this.props.scrollToService();
  }
  handleVideoClick = () => {
    this.props.scrollToVideo();
  }
  handleContactClick = () => {
    this.props.scrollToContact();
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    // code to handle form submission
  }

  render() {
    const { expanded } = this.state;

    return (
      <>
        <div id='div-desktop'>
          <header
            id="header"
            className="fixed-top d-flex align-items-cente"
            style={{ backgroundColor: "white", height: 50 }}
          >
            <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
              <h1 className="logo me-auto me-lg-0">
                <a className="ab" href="index.html">
                  <img
                    src="assets/img/logo.png"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  />
                </a>
              </h1>
              <nav id="navbarlanding" className="navbarlanding order-last order-lg-0">
                <ul>
                  <button className="custom-btn btn-5" onClick={() => { this.setState({ show: true, partnerStatus: true }) }}>
                    <span>Be A Partner</span>

                  </button>
                  <li>
                    <a className="ab nav-link scrollto active" onClick={this.handleServiceClick} style={{ color: "black", cursor: "pointer" }}>
                      Features
                    </a>
                  </li>
                  <li>
                    <a className="ab nav-link scrollto" onClick={this.handleAboutClick} style={{ color: "black", cursor: "pointer" }}>
                      Pricing
                    </a>
                  </li>
                  <li className="dropdown">
                    <a className="ab" href="#">
                      <span>Resources</span> <i className="bi bi-chevron-down" />
                    </a>
                    <ul>
                      <li>
                        <a className="ab" href="#">FAQ</a>
                      </li>
                      <li>
                        <a className="ab" href="#">
                          <span>Helps</span>
                        </a>
                      </li>
                      <li>
                        <a className="ab" onClick={this.handleVideoClick} style={{ color: "black", cursor: "pointer" }}>
                          <span>Videos</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="ab nav-link scrollto" onClick={() => { this.setState({ show: true, partnerStatus: false }) }} style={{ color: "black", cursor: "pointer" }}>
                      Contact Us
                    </a>
                  </li>{" "}
                  --&gt;
                 <a href="/login"> <button className="custom-btn btn-5" style={{borderRadius: "50px",}}>
                    <span>Login</span>

                  </button></a>
                </ul>
                <i className="bi bi-list mobile-nav-toggle" />
              </nav>

              <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }}>
                <Modal.Header closeButton style={{ backgroundColor: "orangered" }}>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                  aspectRatio: " 6/4",
                  // overflowX: "hidden",
                  // overflowY: "scroll",
                }}>
                  <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" value={this.state.name} required name="name" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email" value={this.state.email} required name="email" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="company">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your company name" value={this.state.company} required name="company" onChange={this.handleInputChange} />
                    </Form.Group>

                    <PhoneInput
                                                containerStyle={{ width: "190px",marginTop: "30px" }}
                                                inputStyle={{ width: "470px", height: "35px" }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event }); }} style={{color: "black", marginTop: "30px"}}
                                            />
                    <Form.Group controlId="designation">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control type="text" placeholder="Enter your designation" value={this.state.designation} required name="designation" onChange={this.handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="subject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control  defaultValue={this.state.partnerStatus == true ? "" : "General Inquiry"} as="select" required name="subject" onChange={this.handleInputChange}>
                        <option value="">Oppurtunity for partnership</option>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Sales Inquiry">Sales Inquiry</option>
                        {/* <option value="Support Request">Support Request</option> */}
                      </Form.Control>
                     
                   
                    </Form.Group>
                   

                    <Form.Group controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={this.state.message} required name="message" onChange={this.handleInputChange} />
                    </Form.Group>



                    <Button variant="primary" type="submit" style={{ marginTop: "5px", backgroundColor: "orangered", border: "none" }}>
                      Submit
                    </Button>
                  </Form>
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={() => { this.setState({ show: false }) }}>
                    Close
                  </Button>
                  <Button  variant="primary" style={{backgroundColor: "orangered", border: "none"}}  onClick={() => { this.setState({ show: false })}}>
                    Save Changes
                  </Button>
                </Modal.Footer> */}
              </Modal>


            </div>
          </header>
        </div>
        <div id='div-mobile'>
          <Navbar expanded={expanded} onToggle={() => this.setState({ expanded: !expanded })} bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/"><img
              src="assets/img/logo.png"
              style={{ width: "200px", padding: "10px" }}
            /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link as={Link} ><button className="custom-btn btn-5" onClick={() => { this.setState({ show: true, partnerStatus: true }) }}>
                  <a><span>Be A Partner</span></a>
                </button></Nav.Link>
                <Nav.Link as={Link} to="#hero" state={{ marginLeft: "100px" }}>Features</Nav.Link>
                <Nav.Link as={Link} to="#about">Pricing</Nav.Link>
                <NavDropdown title="Resources" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#">FAQ</NavDropdown.Item>
                  <NavDropdown.Item href="#">Helps</NavDropdown.Item>
                  <NavDropdown.Item href="#">Videos</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="#contact"onClick={() => { this.setState({ show: true, partnerStatus: false }) }}> Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    )

  }
}
