import {
    PROCESS_LIST,
} from '../types';

const initialState = {

    processList: [
        {
            process_id: '',
            process_name: ''
        }
    ]
}

const processReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROCESS_LIST:
            return {
                ...state,
                processList: action.payload
            }
        default:
            return state;
    }

};

export default processReducer;