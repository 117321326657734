import React, { Component } from 'react';
import { Button, ButtonGroup, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import Loader from '../../../Components/Loader';
import { connect } from 'react-redux';
import { setFlashNotification } from '../../../redux/action/uiAction';
import SingleUserDashboard from '../../Dashboard';
import { getThingDatalog, KPIDetails, listThings, listWidgetTypes } from '../../../constants/api';
import { Info } from '@material-ui/icons';

import ReactGridLayout from 'react-grid-layout';
import DoNutChart from '../../../Components/chart/donutchart';
import BarGraph from '../../../Components/chart/barchart';
import Linechart from '../../../Components/chart/linebar';

const data = [
    { xTag: '2022-07-09 03:13:17.607000', yTag: 0 }
    , { xTag: '2022-07-09 03:13:49.329000', yTag: 4 }
    , { xTag: '2022-07-09 03:14:45.164000', yTag: 7 }
    , { xTag: '2022-07-09 03:15:17.123000', yTag: 0 }
    , { xTag: '2022-07-09 03:15:48.968000', yTag: 5 }
    , { xTag: '2022-07-09 03:16:20.873000', yTag: 0 }
    , { xTag: '2022-07-09 03:16:52.795000', yTag: 0 }
    , { xTag: '2022-07-09 03:17:24.523000', yTag: 3 }
    , { xTag: '2022-07-09 03:17:56.418000', yTag: 0 }
    , { xTag: '2022-07-09 03:18:28.375000', yTag: 9 }
]
const piechartData = [
    { name: 'Thing A', value: 400 }, { name: 'Thing B', value: 300 },
    { name: 'Thing C', value: 300 }, { name: 'Thing D', value: 200 },
    { name: 'Thing E', value: 278 }, { name: 'Thing F', value: 189 },
];

const barChartData = [
    {
        name: '2013', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: '2014', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: '2015', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: '2016', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: '2017', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: '2018', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: '2019', uv: 3490, pv: 4300, amt: 2100,
    },
];

const charts = ['linechart', 'piechart', 'barchart']

class AnalyticsKPIDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dashboard_id: this.props.match.params.dashboard_id,
            dashboard_name: "",
            loading: false,
            addWidgetDialog: false,
            dashboard_widgets: [],
            thingSearch: "",
            thing_list: [],
            projects_loading: false,
            selected_project: null,
            widget_types: [],
            selected_widget_type: null,
            widget_values: [],
            selected_value: null,
            shareDialog: false,
            layout: '',
            thingSchemaData: [],
            KPIType: 'raw',
            variable: ""
        }
    }

    componentDidMount = () => {
        this.fetchKPIDetails()
    }
    fetchKPIDetails = () => {
        this.setState({ thing_loading: true })
        KPIDetails(this.props.userData.access_token, this.props.match.params.project_id, this.props.match.params.kpi_id,
            (success) => {
                this.setState({ thing_loading: false })
            },
            (failure) => {
                alert(failure.msg)
                this.setState({ thing_loading: false })

            },
            (error) => {
                alert(error.msg)
                this.setState({ thing_loading: false })

            })
    }

    _renderToolbar = () => {
        return (
            <div style={{ width: "100%" }}>
                <div style={{ float: 'right' }}>
                    <ButtonGroup style={{ justifyContent: 'space-evenly' }} color="primary" size="medium" variant="outlined" aria-label="outlined primary button group">
                        <Button onClick={(event) => { this.setState({ addWidgetDialog: true }); this._addWidgetDialogLoading() }} >Add KPI</Button>
                        <Button onClick={(event) => {
                            this.setState({ shareDialog: true })
                        }} >Share</Button>

                    </ButtonGroup>
                </div>

            </div>
        )
    }

    fetchThingList(thingSearch = '') {
        this.setState({ thing_loading: true })
        listThings(this.props.userData.access_token, this.props.match.params.project_id, 1, thingSearch,
            (success) => {
                this.setState({ thing_list: success.data.things })
                this.setState({ thing_loading: false })
            },
            (failure) => {
                alert(failure.msg)
                this.setState({ thing_loading: false })

            },
            (error) => {
                alert(error.msg)
                this.setState({ thing_loading: false })

            })
    }

    fetchWidgetTypes() {
        listWidgetTypes((success) => {
            this.setState({ widget_types: success.data })
        }, (failure) => {
            alert(failure.msg)
        },
            (error) => {
                alert(error)
            })
    }

    _addWidgetDialogLoading = () => {
        this.fetchThingList()
    }


    addKPI = () => {
        // console.log(this.state.selectedThing)
    }

    getThingDetails = (thing) => {
        var today = new Date();
        var yesterday = new Date();

        var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        var yesterday_date = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate();
        getThingDatalog(
            this.props.userData.access_token,
            this.props.userData.username,
            this.props.match.params.project_id,
            thing.thing_id,
            yesterday_date,
            date,
            successResponse => {
                var thingSchemaData = successResponse.thing_schema.map((value, index) => {
                    return value.name
                })
                // console.log("thingSchemaData", thingSchemaData)
                this.setState({ thingSchemaData: thingSchemaData })
            },
            failureResponse => {
                this.props.setFlashNotification(failureResponse, 'error')
                this.props.history.push('/login')
            },
            errorResponse => {
                this.props.setFlashNotification(errorResponse, 'error')
            }
        );
    }

    _renderAddWidgetDialog() {
        return (
            <Dialog
                fullWidth
                open={this.state.addWidgetDialog}
                onClose={() => { this.setState({ addWidgetDialog: false }) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Add KPI`}</DialogTitle>
                <DialogContent>
                    <div onChange={(e) => this.setState({ KPIType: e.target.value })} style={{ flexDirection: "row", justifyContent: "space-around", display: "flex", marginBlock: 10 }}>
                        <span style={{ fontSize: 16, fontWeight: "bold" }}><input type="radio" value="raw" name="KPIType" checked={this.state.KPIType === 'raw'} /> Raw</span>
                        <span style={{ fontSize: 16, fontWeight: "bold" }}> <input type="radio" value="derived" name="KPIType" checked={this.state.KPIType === 'derived'} /> Derived</span>
                    </div>
                    {this.state.KPIType == 'raw' ?
                        <Autocomplete
                            fullWidth
                            filterOptions={createFilterOptions({
                                matchFrom: 'start',
                                stringify: option => option.thing_name,
                            })}
                            options={this.state.thing_list}
                            onChange={(event, newValue) => {
                                this.setState({ selectedThing: newValue })
                            }}
                            onInputChange={(e) => this.fetchThingList(e.target.value)}
                            loading={this.state.thing_loading}
                            autoHighlight
                            getOptionLabel={(option) => option.thing_name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.thing_name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Choose a Thing"
                                        variant="outlined"

                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )
                            }}
                        /> :
                        <div >
                            <TextField
                                id="outlined-dense1"
                                label="Variable"
                                style={{ width: "91%" }}
                                margin="dense"
                                value={this.state.variable}
                                variant="outlined"
                                required
                                onChange={(event) => { this.setState({ variable: event.target.value }) }}
                            />
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 7 }}>
                                <Autocomplete
                                    fullWidth
                                    filterOptions={createFilterOptions({
                                        matchFrom: 'start',
                                        stringify: option => option.thing_name,
                                    })}
                                    options={this.state.thing_list}
                                    onChange={(event, newValue) => {
                                        this.getThingDetails(newValue)
                                        this.setState({ selectedThing: newValue })
                                    }}
                                    onInputChange={(e) => this.fetchThingList(e.target.value)}
                                    loading={this.state.thing_loading}
                                    autoHighlight
                                    getOptionLabel={(option) => option.thing_name}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.thing_name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                label="Choose a Thing"
                                                variant="outlined"

                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />)
                                    }}
                                />
                                <IconButton aria-label="Example" >
                                    <Tooltip title={this.state.thingSchemaData.length > 0 && this.state.thingSchemaData.join(' | ') == '' ? 'No Schema Here' : this.state.thingSchemaData.join(' | ')}>
                                        <Info />
                                    </Tooltip>
                                </IconButton>
                            </div>
                        </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ addWidgetDialog: false, }) }} autoFocus color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(event) => {
                        this.addKPI()
                    }} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    showCharts(label) {
        switch (label) {
            case 'linechart':
                return <Linechart
                    trendName={new Date().toLocaleDateString()}
                    data={data}
                    height={"100%"}
                    width={"100%"}
                />
            case 'piechart':
                return <DoNutChart height={"100%"} width={"100%"} piechartData={piechartData} />
            case 'barchart':
                return <BarGraph height={"100%"} width={"100%"} data={barChartData} />
            default:
                return <div>{label}</div>
        }
    }



    render() {

        const layout = [
            { i: "0", x: 0, y: 0, w: 5, h: 2 },
            { i: "1", x: 5, y: 0, w: 4, h: 2 },
            { i: "2", x: 0, y: 0, w: 5, h: 2 }
        ];

        return (
            <SingleUserDashboard>
                <Loader loading={this.state.loading} />
                {this._renderAddWidgetDialog()}
                <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{ float: "left" }}>
                            <h5 className="pageContentTitle">Analytics</h5>
                        </div>
                        <div>{this._renderToolbar()}</div>
                    </Grid>
                </Grid>
                <Grid container className='dashboardContent'>
                    <ReactGridLayout
                        className="layout"
                        layout={layout}
                        width={1200}
                    >
                        {charts.map((value, index) => {
                            return (
                                <div key={index} className="grid-item">
                                    {this.showCharts(value)}
                                </div>
                            )
                        })}
                    </ReactGridLayout>
                </Grid>
            </SingleUserDashboard>
        );
    }


}

const mapPropsToState = (store) => {
    return {
        userData: store.userReducer.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFlashNotification: (msg, color) => dispatch(setFlashNotification(msg, color)),
    }
}

export default connect(mapPropsToState, mapDispatchToProps)(AnalyticsKPIDetails);
