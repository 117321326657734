
export const BASEURL = process.env.REACT_APP_EPSUMTHINGS_BASEURL;
export const IRBASEURL = process.env.REACT_APP_IRBLASTER_URL;
export const WEBSOCKET_BASEURL = process.env.REACT_APP_WEBSOCKET_BASEURL;
export const MQTT_URL = process.env.REACT_APP_MQTT_URL;
export const MQTT_CLIENT_ID = 'MQTT_EPSUMTHINGS_UI'

export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
}

export const maxmSpeed = 55.5556  // meter per sec
export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0"