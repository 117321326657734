import React, { Component } from 'react';
import { connect } from "react-redux";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { unsetFlashNotification } from '../../redux/action/uiAction';

class FlashNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Snackbar open={this.props.flashNotification.visibility} autoHideDuration={3000} onClose={() => this.props.ResetFlashNotification()}>
                <Alert
                    onClose={() => { this.props.ResetFlashNotification() }}
                    variant="filled"
                    severity={this.props.flashNotification.color}
                    sx={{ width: '100%' }} >
                    {this.props.flashNotification.message}
                </Alert>
            </Snackbar>
        );
    }
}

const mapPropsToState = (store) => {
    return {
        flashNotification: store.uiReducer.flashNotification
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ResetFlashNotification: () => dispatch(unsetFlashNotification()),
    }
}
export default (connect(mapPropsToState, mapDispatchToProps)(FlashNotification));
