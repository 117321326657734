import {
    LOGIN,
    ACCESS_TOKEN,
    LOGOUT,
    PROFILE_DATA,
    ORGANIZATION_USER_LIST,
    USER_GROUP_LIST,
    THING_ALARM_LIST,
} from '../types';

const initialState = {

    access_token: '',
    userData: {},
    profileData: {},
    organisationUserList: [],
    userGroupList: [],
    organisationUserCount: 0,
    thingAlarmList: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case THING_ALARM_LIST:
            return {
                ...state,
                thingAlarmList: action.payload
            };
        case USER_GROUP_LIST:
            return {
                ...state,
                userGroupList: action.payload
            }
        case ORGANIZATION_USER_LIST:
            return {
                ...state,
                organisationUserList: action.payload.data,
                organisationUserCount: action.payload.count
            }
        case ACCESS_TOKEN:
            return {
                ...state,
                access_token: 'Bearer ' + action.payload
            };
        case PROFILE_DATA:
            return {
                ...state,
                profileData: action.payload
            };
        case LOGIN:
            return {
                ...state,
                userData: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                userData: {},
                profileData: {},
                organisationUserList: [],
                userGroupList: [],
            };
        default:
            return state;

    }

};

export default userReducer;