import React, {Component} from 'react';
import Header from './Navbar';
import Footer from './Footer';
import privacyimg from '../images/privacy.jpg';
import Grid from '@material-ui/core/Grid';

export default class PrivacyPolicy extends Component{
    render(){
        return(
            <div>
                <Header/>
                    <div className="div_blog">
                        <img src={privacyimg} alt="Blogs" className="div_blog_image"/>
                    </div>
                    <div className="container mt-2">
                        <div className="starter-template">
                            <h2 className="application-areas-text app_abt_us" data-aos="fade-up" data-aos-easing="linear"
                                data-aos-duration="1500">Privacy Policy</h2> 
                                <hr className="style-seven"></hr> 
                                <Grid container spacing={2} className="mb-5">
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign:'left'}}>
                                    <br/>
                                        <p>By visiting this website you agree to be bound by the terms and conditions of this privacy policy. If you do not agree, please do not use or access our website. By mere use of the Website, you expressly consent to, our use and disclosure, of your personal information following this Privacy Policy. This Privacy Policy is subject to the Terms of Services.</p>
                                        <p>We, at Toiljobs value the trust you place in us. We insist upon the highest standards for secure transactions and customer's information privacy. Please read the following statements to learn about our information gathering and dissemination practices. Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</p>
                                        <p>We, at 'Epsum Labs' and our affiliated companies/organizations worldwide, are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. To provide a personalized browsing experience, Toiljobs.com may collect information from you, this may include technical or other related information from the device used to access Toiljobs.com including without limitation contact lists in your device and its location, you may also be asked to complete a registration form. When you let us have your preferences, we will be able to deliver or allow you to access the most relevant information that meets your end.</p>
                                        <p>"Personal Information" means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or home. In general, you can visit all web pages without telling us who you are or revealing any Personal Information about yourself.</p><br/>
                                        <h4>Cookies and Other Tracking Technologies</h4>
                                        <p>Some of our web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file that may be used, for example, to collect information about activity on the web site. Some cookies and other technologies may serve to recall Personal Information previously indicated by a Web user. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.</p>
                                        <p>You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to erase or block your cookies, you will need to re-enter your original user ID and password to gain access to certain parts of the web site. </p>
                                        <p>Tracking technologies may record information such as internet domain and host names; internet protocol (IP) addresses; browser software and operating system types; clickstream patterns; and dates and times that our site is accessed. Our use of cookies and other tracking technologies allows us to improve our web site and your web experience. We may also analyze information that does not contain Personal Information for trends and statistics.</p><br/>
                                        <h4>Third Party Services</h4>
                                        <p>Third parties provide certain services available on www.toiljobs.com on Funds and Toil's behalf. 'Epsum Labs' may provide information, including Personal Information, that 'Epsum Labs' collects on the web to third-party service providers to help us deliver programs, products, information, and services. Service providers are also an important means by which 'Epsum Labs' maintains its websites and mailing lists. 'Epsum Labs' will take reasonable steps to ensure that these third-party service providers are obligated to protect Personal Information on Epsum Labs' behalf.</p>
                                        <p>'Epsum Labs' does not intend to transfer Personal Information without your consent to third parties who are not bound to act on Epsum Labs' behalf unless such transfer is legally required. Similarly, it is against Epsum Labs' policy to sell Personal Information collected online without consent.</p><br/>
                                        <h4>Your Consent</h4>
                                        <p>By using this web site, you consent to the terms of our online Privacy Policy and Epsum Labs' processing of Personal Information for the purposes given above as well as those explained where 'Epsum Labs' collects Personal Information on the web</p><br/>
                                        <h4>Information security</h4>
                                        <p>•    We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data</p>
                                        <p>•	We restrict access to your personally-identifying information to employees who need to know that information to operate, develop or improve our services</p><br/>
                                        <h4>Updating your information</h4>
                                        <p>We provide mechanisms for updating and correcting your personally identifying information for many of our services. For more information, please see the help pages for each service</p><br/>
                                        <h4>Information Sharing and Disclosure</h4>
                                        <ul type="disc" className="privacy">
                                            <li>'Epsum Labs' does not rent, sell, or share personal information about you with other people (save with your consent) or non-affiliated companies except to provide products or services you've requested, when we have your permission, or under the following circumstances
                                                <ul type="circle"  className="pricavy">
                                                    <li>We provide the information to trusted partners who work on behalf of or with 'Epsum Labs' under confidentiality agreements. These companies may use your personal information to help 'Epsum Labs’ communicate with you about offers from 'Epsum Labs and our marketing partners. However, these companies do not have any independent right to share this information</li>
                                                    <li>We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims</li>
                                                    <li>We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Epsum Labs's terms of use, or as otherwise required by law</li>
                                                    <li>We transfer information about you if 'Epsum Labs' is acquired by or merged with another company. In this event, 'Epsum Labs' will notify you before information about you is transferred and becomes subject to a different privacy policy</li>
                                                </ul>
                                            </li>
                                            <li>
                                                'Epsum Labs' displays targeted advertisements based on personal information. Advertisers (including ad serving companies) may assume that people who interact with, view, or click on targeted ads meet the targeting criteria - for example, women ages 18-24 from a particular geographic area
                                                <ul type="circle" className="privacy">
                                                    <li>'Epsum Labs' does not provide any personal information to the advertiser when you interact with or view a targeted ad. However, by interacting with or viewing an ad you are consenting to the possibility that the advertiser will make the assumption that you meet the targeting criteria used to display the ad</li>
                                                    <li>'Epsum Labs' advertisers include financial service providers (such as banks, insurance agents, stock brokers and mortgage lenders) and non-financial companies (such as stores, airlines, and software companies)</li>
                                                </ul>
                                            </li>
                                        </ul><br/>
                                        <h4>Confidentiality and Security</h4>
                                        <p>•	We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs</p>
                                        <p>•	"We have physical, electronic, and procedural safeguards that comply with the laws prevalent in India to protect personal information about you. We seek to ensure compliance with the requirements of the Information Technology Act, 2000 as amended and rules made thereunder to ensure the protection and preservation of your privacy</p><br/>
                                        <h4>Changes to this Privacy Policy</h4>
                                        <p>'Epsum Labs' reserves the right to update, change or modify this policy at any time. The policy shall come to effect from the date of such update, change or modification.</p><br/>
                                        <h4>Disclaimer </h4>
                                        <p>Epsum Labs uses the maximum care as is possible to ensure that all or any data / information in respect of electronic transfer of money does not fall in the wrong hands. For completing online transactions involving payments a user is directed to a secured payment gateway, Epsum Labs does not store or keep financial data such as credit card numbers/passwords/PINs etc "Personal Account Related Information". Since the transaction happens on a third party network not controlled by Epsum Labs, once an online transaction has been completed, the Personal Account Related Information is not accessible to anyone at Epsum Labs after completion of the online transaction at the payment gateway, this ensures maximum security.<br/><br/>Epsum Labs shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the user's account and / or information relating to or regarding online transactions using credit cards / debit cards and / or their verification process and particulars nor for any error, omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a legal process or otherwise</p><br/>
                                        <h4>Contact Information</h4>
                                        <p>'Epsum Labs' welcomes your comments regarding this privacy statement at the contact address given at the website. Should there be any concerns about contravention of this Privacy Policy, 'Epsum Labs' will employ all commercially reasonable efforts to address the same. 
                                        Note : The terms in this agreement may be changed by Epsum Labs at any time. Epsum Labs is free to offer its services to any client/prospective client without restriction
                                        </p>
                                    </Grid>
                                </Grid>
                        </div>
                    </div>
                <Footer/>
            </div>
        );
    }
}