import React, {Component} from 'react';

export default class Alarmsred extends Component{
    render(){
      return(
          <div className="alarm">
            <div className="light">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="bulb">
              <div className="eyes">
                <span></span><span></span>
              </div>
            </div>
            <div className="base"></div>
          </div>
      );
    }
}

class Alarmgreen extends Component{
  render(){
    return(
        <div className="alarm">
          <div className="light">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="bulb">
            <div className="eyes">
              <span></span><span></span>
            </div>
          </div>
          <div className="base"></div>
        </div>
    );
  }
}

class Alarmyellow extends Component{
  render(){
    return(
        <div className="alarm">
          <div className="light">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="bulb">
            <div className="eyes">
              <span></span><span></span>
            </div>
          </div>
          <div className="base"></div>
        </div>
    );
  }
}
