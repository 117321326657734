const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const PROJECTS = "PROJECTS"
const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const USER_DATA = 'USER_DATA'
export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const THINGS_LIST = 'THINGS_LIST'
export const PROJECTS_LIST = 'PROJECTS_LIST'
export const PROCESS_LIST = 'PROCESS_LIST'
export const SCOPE_LIST = 'SCOPE_LIST'
export const IR_REMOTES_LIST = 'IR_REMOTES_LIST'
export const CAMERA_LIST = 'CAMERA_LIST'
export const WEBHOOKS_LIST = 'WEBHOOKS_LIST'
export const GROUP_LIST = 'GROUP_LIST'
export const ROLES_LIST = 'ROLES_LIST'
export const LOADING_START = 'LOADING_START'
export const LOADING_END = 'LOADING_END'
export const FLASH_NOTIFICATION = 'FLASH_NOTIFICATION'
export const HIDE_FLASH_NOTIFICATION = 'HIDE_FLASH_NOTIFICATION'
export { LOGIN, LOGOUT, PROJECTS, PROFILE_UPDATE };
export const PERMISSION_LIST = 'PERMISSION_LIST';
export const ROLE_PERMISSION_LIST = 'ROLE_PERMISSION_LIST';
export const PROFILE_DATA = 'PROFILE_DATA';
export const ORGANIZATION_USER_LIST = 'ORGANIZATION_USER_LIST';
export const USER_GROUP_LIST = 'USER_GROUP_LIST';
export const GROUP_ROLES_LIST = 'GROUP_ROLES_LIST';
export const THING_ALARM_LIST = 'THING_ALARM_LIST';

export const ACCOUNT_NO = 'ACCOUNT_NO';
export const SELECTED_PACKAGE_DETAILS = 'SELECTED_PACKAGE_DETAILS';
export const SELECTED_ADDON_LIST = 'SELECTED_ADDON_LIST';
export const SELECTED_ADDON_DETAILS_LIST = 'SELECTED_ADDON_DETAILS_LIST';
export const CLEAR_CART = 'CLEAR_CART';
export const SELECTED_PAGE_NO = 'SELECTED_PAGE_NO';
export const SELECTED_PAGE_NO_OF_PROJECTS = 'SELECTED_PAGE_NO_OF_PROJECTS';
export const SELECTED_PAGE_NO_OF_WEBHOOK = 'SELECTED_PAGE_NO_OF_WEBHOOK';