import {
    DASHBOARD_THING, DASHBOARD_COMPONENT
} from './actionType';

const initialState = {
    dashboardThing: [],
    dashboardComponent: []
}

const thingDashboard = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_THING:
            return {
                ...state,
                dashboardThing: action.payload
            }
        case DASHBOARD_COMPONENT:
            return {
                ...state,
                dashboardComponent: action.payload
            }
        default:
            return state;

    }

};

export default thingDashboard;