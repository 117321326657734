import React, { Component } from "react";
import Logo from '../images/epsumThings Logo.png';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


export default class NavbarPage extends Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-fixed-top">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="Responsive image" className="logo_text" />
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
          {/* <span className="navbar-toggler-icon iconStyle"></span> */}
          <span><i className="fas fa-align-right iconStyle"></i></span>
        </button>

        <div className="collapse navbar-collapse sub-menu-bar" id="mainMenu">

          {/* <ul className="navbar-nav ml-auto navList">
                <li className="nav-item active">
                  <Link to="/" className="nav-link"><i className="fas fa-home"></i>HOME<span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item" activeclassname="active">
                    <Link to="/about-us" className="nav-link"><i className="fas fa-users"></i>About US</Link>
                </li>
                <li className="nav-item" activeclassname="active">
                    <Link to="/products" className="nav-link"><i className="fas fa-project-diagram"></i>Products</Link>
                </li>
                <li className="nav-item" activeclassname="active">
                    <Link  to="/support" className="nav-link"><i className="fas fa-phone"></i>Support</Link>
                </li>
                <li className="nav-item" activeclassname="active">
                    <Link to="/blog" className="nav-link"><i className="fas fa-blog"></i>Blog</Link>
                </li>
                <li className="nav-item" activeclassname="active">
                    <Link  to="/login" className="nav-link"><i className="fas fa-sign-in-alt"></i>Login</Link>
                </li>
            </ul> */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="page-scroll" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="page-scroll" target="_blank" to href="https://www.epsumlabs.com/about-us">About Us</a>
            </li>
            <li className="nav-item">
              <a className="page-scroll" target="_blank" href="https://www.epsumlabs.com/internet-of-things"> Products</a>
            </li>
            {/* <li className="nav-item mobile_screen_login">
              <a className="page-scroll" href="/login"> Login/Register</a>
            </li> */}
          </ul>
        </div>
        <div className="navbar-btn">
          <a className="main-btn" data-scroll-nav="0" href="/login">Login/Register</a>
        </div>
      </nav>
    );
  }
}