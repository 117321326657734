import { combineReducers } from 'redux';
import userReducer from './userReducer';
import thingsReducer from './thingsReducer'
import projectsReducer from './projectsReducer'
import irReducer from './irReducer'
import cameraReducer from './cameraReducer'
import uiReducer from './uiReducer'
import WebhooksReducer from './webhooks'
import processReducer from './processReducer'
import scopeReducer from './scopeReducer'
import permissionReducer from './permissionReducer'
import groupReducer from './groupReducer'
import rolesReducer from './rolesReducer'
import imageStreamReducer from '../../panel/ImageStream/reducer';
import packageReducer from './packageReducer';
import thingDashboard from '../../panel/Dashboard/reducer';

const rootReducer = combineReducers({
    userReducer,
    thingsReducer,
    projectsReducer,
    irReducer,
    cameraReducer,
    uiReducer,
    WebhooksReducer,
    processReducer,
    scopeReducer,
    permissionReducer,
    groupReducer,
    rolesReducer,
    imageStreamReducer,
    packageReducer,
    thingDashboard
})

export default rootReducer;
