import * as Types from './actionTypes';

const initialState = {
    imageStreamList: [],
    imageStreamData: []
};

const imageStreamReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.IMAGE_STREAM_LIST:
            return {
                ...state,
                imageStreamList: action.payload
            };
        case Types.IMAGE_STREAM_DATA:
            return {
                ...state,
                imageStreamData: action.payload
            }
        default:
            return state;
    }
};

export default imageStreamReducer;
