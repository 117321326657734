import {
    ROLES_LIST,
} from '../types';

const initialState = {

    rolesList: [],
    roleCount: 0
}

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLES_LIST:
            return {
                ...state,
                rolesList: action.payload.data,
                roleCount: action.payload.count
            }
        default:
            return state;
    }

};

export default rolesReducer;