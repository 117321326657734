import React, {Component} from 'react';
import Alarms from './Alarm';
import {alarmsTable_rows} from '../constants/selectableconstants';
export default class Alarm extends Component{
    render(){
      return( 
            <div className="scroll-table">
              <table className="table" style={{marginTop:'-50px'}} >
                <tbody>
                  {alarmsTable_rows.map(row => (
                    <tr>
                      <td><i className="material-icons" style={{color:'#aaa'}}> notifications_active </i> &nbsp;&nbsp;{row.alarm_name}</td>
                      <td><Alarms/></td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
      );
    }
}
