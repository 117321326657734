import {
    PERMISSION_LIST, ROLE_PERMISSION_LIST, LOGOUT
} from '../types';

const initialState = {

    permissionList: [],
    rolePermissionList: []
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSION_LIST:
            return {
                ...state,
                permissionList: action.payload
            }
        case ROLE_PERMISSION_LIST:
            return {
                ...state,
                rolePermissionList: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                permissionList: [],
                rolePermissionList: []
            }
        default:
            return state;
    }

};

export default permissionReducer;