import React, { Component } from 'react';
import LandingPage from './landing_page/Landingpage';
// import LandingPage from '../src/LandingPage/Landing';
// import TandC from './LandingPage/t&c';
import PandP from './LandingPage/p&p';
// import Faqs from './LandingPage/faqs';
// import Aboutus from './LandingPage/About';
// import Products from './LandingPage/Product';
// import Support from './LandingPage/Support';
// import Blog from './LandingPage/Blog';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import AnalyticsKPIDetails from './panel/Analytics/component/AnalyticsKPIDetails';

const Group_Subgroup = lazy(() => import('./panel/Group_Subgroup'));
const BulkInsert = lazy(() => import('./panel/BulkInsert'));
const Advance_GPS_History = lazy(() => import('./panel/Advance_GPS_History'));
const Advance_GPS_Reports = lazy(() => import('./panel/Advance_GPS_Reports'));
const DistanceReport = lazy(() => import('./panel/DistanceReport'));
const StatusReport = lazy(() => import('./panel/StatusReport'));
const TravelSummaryReport = lazy(() => import('./panel/TravelSummaryReport'));
const AssignUser_Group = lazy(() => import('./panel/AssignUser_Group'));

const Profile = lazy(() => import('./panel/Profile'));
const Logs = lazy(() => import('./panel/Userlogs'));
const Alarm = lazy(() => import('./panel/Alarm'));
const AlarmLog = lazy(() => import('./panel/AlarmLog'));
const Analytics = lazy(() => import('./panel/Analytics/component/Analytics'));
const Users = lazy(() => import('./panel/Users'));
const UserRole = lazy(() => import('./panel/UserRole'));
const IRRemote = lazy(() => import('./panel/IRRemote'));
const IRRemotes = lazy(() => import('./panel/IRRemotes'));
// const Webscada = lazy(() => import('./panel/Webscada'));
const Camera = lazy(() => import('./panel/Camera'));
const Webhooks = lazy(() => import('./panel/Webhooks'));
const Thing = lazy(() => import('./panel/Thing/component/Thing'));
// const Thing = lazy(() => import('./panel/Thing/component/Thing_bak'));
// const Thing = lazy(() => import('./panel/Thing'));
const Project = lazy(() => import('./panel/Project'));
const Schema = lazy(() => import('./panel/Schema'));
const Project_1 = lazy(() => import('./panel/ProjectPage'));
const Things = lazy(() => import('./panel/Thing/component/Things'));
// const Things = lazy(() => import('./panel/Things'));
// const ProcessList = lazy(() => import('./panel/ProcessList'));
// const Process = lazy(() => import('./panel/Process'));
const Apps = lazy(() => import('./panel/Apps'));
const Trends = lazy(() => import('./panel/Trends'));
const Trend = lazy(() => import('./panel/Trend'));
const Register = lazy(() => import('./panel/Register'));
const Error = lazy(() => import('./Components/error'));
const Login = lazy(() => import('./panel/Login'));
const Welcome = lazy(() => import('./panel/Welcomepage'));
// const AllComponent = lazy(() => import('./Components/AllComponents'));
const AccessControl = lazy(() => import('./panel/AccessControl'));
const ResetPassword = lazy(() => import('./panel/resetPassword'));
const ImageStream = lazy(() => import('./panel/ImageStream/components/ImageStream'));
const ImageStreamDetails = lazy(() => import('./panel/ImageStream/components/ImageStreamDetails'));

const WebScada = lazy(() => import('./panel/WebScada/components/WebScada'));
const WebScadaView = lazy(() => import('./panel/WebScada/components/WebScadaView'));

const Group = lazy(() => import('./panel/Group'))
const Dashboard = lazy(() => import('./panel/Dashboard/component/Dashboard'));
// const DashboardDetails = lazy(() => import('./panel/Dashboard/component/DashboardDetails'));
const DashboardDetails = lazy(() => import('./panel/Dashboard/component/DashboardDetails'));

const DashboardShared = lazy(() => import('./panel/Dashboard/component/DashboardShared'));

const Upgrade = lazy(() => import('./panel/Upgrade'));
const Package = lazy(() => import('./panel/Package'));
const Integration = lazy(() => import('./panel/Integration'));
const AddOnFeatures = lazy(() => import('./panel/AddOnFeatures'));
const BillingSummary = lazy(() => import('./panel/BillingSummary'));
const BillingAccount = lazy(() => import('./panel/BillingAccount'));
const AllProperties = lazy(() => import('./panel/AllProperties'));

class App extends Component {
  render() {
    return (
      <Router>
        <Suspense fallback={<div style={{ display: 'flex', marginTop: "10%", alignItems: 'center', justifyContent: "center" }}>
          <div >Please allow us sometime while we process your request.</div>
        </div>}>
          <Switch>
            <Route exact path="/" component={LandingPage} />

            {/* <Route exact path="/about-us" component={Aboutus} /> */}
            {/* <Route exact path="/products" component={Products} /> */}
            {/* <Route exact path="/support" component={Support} /> */}
            {/* <Route exact path="/blog" component={Blog} /> */}
            {/* <Route exact path="/termsconditions" component={TandC} /> */}

            {/* <Route exact path="/privacypolicy" component={PandP} /> */}

            {/* <Route exact path="/faqs" component={Faqs} /> */}
            {/* <Route exact path="/" component={Login} /> */}
            <Route exact path="/login" component={Login} />

            <Route exact path="/register" component={Register} />
            <Route exact path="/home" component={Welcome} />
            <Route exact path="/apps" component={Apps} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/advance_gps_reports" component={Advance_GPS_Reports} />
            <Route exact path="/advance_gps_history/:project_id/:thing_id" component={Advance_GPS_History} />
            <Route exact path="/advance_gps_history/:project_id/:thing_id" component={Advance_GPS_History} />
            <Route exact path="/distance_report/" component={DistanceReport} />
            <Route exact path="/status_report/" component={StatusReport} />
            <Route exact path="/travel_summary_report/" component={TravelSummaryReport} />
            <Route exact path="/groups/" component={Group_Subgroup} />
            <Route exact path="/assign_user_group/" component={AssignUser_Group} />
            <Route exact path="/bulk_insert/" component={BulkInsert} />
            <Route exact path="/all_properties/" component={AllProperties} />

            <Route exact path="/logs" component={Logs} />
            <Route exact path="/projects" component={Project} />
            <Route exact path="/projects/:project_id" component={Project_1} />
            <Route exact path="/projects/:project_id/things" component={Things} />
            <Route exact path="/projects/:project_id/webhooks" component={Webhooks} />
            <Route exact path="/projects/:project_id/things/:thing_id" component={Thing} />
            <Route exact path="/projects/:project_id/camera" component={Camera} />
            <Route exact path="/projects/:project_id/imagestream" component={ImageStream} />
            <Route exact path="/projects/:project_id/imagestream/:imagestream_id" component={ImageStreamDetails} />
            <Route exact path="/projects/:project_id/trends" component={Trends} />
            <Route exact path="/projects/:project_id/trends/:trend_id" component={Trend} />
            <Route exact path="/projects/:project_id/alarms" component={Alarm} />
            <Route exact path="/projects/:project_id/alarms/:alarm_id" component={AlarmLog} />
            <Route exact path="/projects/:project_id/irremotes" component={IRRemotes} />
            <Route exact path="/projects/:project_id/irremotes/:remote_id" component={IRRemote} />
            <Route exact path="/projects/:project_id/webscada" component={WebScada} />
            <Route exact path="/projects/:project_id/webscada/:scada_id" component={WebScadaView} />
            <Route exact path="/projects/:project_id/analytics" component={Analytics} />
            <Route exact path="/projects/:project_id/analytics/:kpi_id" component={AnalyticsKPIDetails} />

            {/* <Route exact path="/projects/:project_id/process" component={ProcessList} />
            <Route exact path="/projects/:project_id/process/:process_id" component={Process} /> */}
            {/* <Route exact path="/process" component={Process} /> */}
            <Route exact path="/schema" component={Schema} />
            {/* <Route exact path="/allcomponents" component={AllComponent} /> */}
            <Route exact path="/userrole" component={UserRole} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/group" component={Group} />
            <Route exact path="/accesscontrol/:role_id" component={AccessControl} />
            <Route exact path="/user/forgotpassword/:token" component={ResetPassword} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/shared" component={DashboardShared} />
            <Route exact path="/dashboard/:dashboard_id" component={DashboardDetails} />
            <Route exact path="/upgrade" component={Upgrade} />

            <Route exact path="/package" component={Package} />
            <Route exact path="/integration" component={Integration} />
            <Route exact path="/addonfeatures" component={AddOnFeatures} />
            <Route exact path="/billingaccount" component={BillingAccount} />
            <Route exact path="/billingsummary" component={BillingSummary} />

            <Route component={Error} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}
export default App;
