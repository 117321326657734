import { CAMERA_LIST, LOGOUT } from '../types';

const initialState = {

    cameraList: [],
    cameraCount: 0,
}

const cameraReducer = (state = initialState, action) => {
    switch (action.type) {
        case CAMERA_LIST:
            return {
                ...state,
                cameraList: action.payload.data,
                cameraCount: action.payload.count,
            }
        case LOGOUT:
            return {
                ...state,
                cameraList: [],
                cameraCount: 0,
            }
        default:
            return state;
    }

};

export default cameraReducer;