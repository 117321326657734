import { PROJECTS_LIST, SELECTED_PAGE_NO_OF_PROJECTS, LOGOUT } from '../types';

const initialState = {

    projectList: {
        "projects_count": 0,
        "data": []
    },
    project_selected_page_no: '',
}

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROJECTS_LIST:
            return {
                ...state,
                projectList: action.payload
            }
        case SELECTED_PAGE_NO_OF_PROJECTS:
            return {
                ...state,
                project_selected_page_no: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                projectList: {},
                project_selected_page_no: '',
            }
        default:
            return state;
    }

};

export default projectsReducer;