import {
    ACCOUNT_NO,
    SELECTED_PACKAGE_DETAILS,
    SELECTED_ADDON_LIST,
    SELECTED_ADDON_DETAILS_LIST,
    CLEAR_CART
} from '../types';

const initialState = {

    billing_account_no: '',
    selected_package_details: {},
    selected_addon_list:[],
    selected_addon_details_list:[],
}

const packageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_NO:
            return {
                ...state,
                billing_account_no: action.payload
            };
        case SELECTED_PACKAGE_DETAILS:
            return {
                ...state,
                selected_package_details: action.payload
            };
        case SELECTED_ADDON_LIST:
            return {
                ...state,
                selected_addon_list: action.payload
            };
        case SELECTED_ADDON_DETAILS_LIST:
            return {
                ...state,
                selected_addon_details_list: action.payload
            };
        case CLEAR_CART:
            return {
                ...state,
                billing_account_no: '',
                selected_package_details: {},
                selected_addon_list:[],
                selected_addon_details_list:[]
            };
        default:
            return state;
    }

};

export default packageReducer;