import { FLASH_NOTIFICATION } from '../types';
const initialState = {
    flashNotification: {
        "message": '',
        "color": '',
        "visibility": false
    },
    };

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FLASH_NOTIFICATION:
            return {
                ...state,
                flashNotification: action.payload
            };
        default:
            return state;
    }
};

export default uiReducer;
