import React, { PureComponent } from 'react';
import {
  PieChart, Pie, ResponsiveContainer, Tooltip,
} from 'recharts';


export default class DoNutChart extends PureComponent {
  render() {
    return (
      <div style={{ width: this.props.width, height: this.props.height }}>
        <ResponsiveContainer>
          <PieChart width={this.props.width} height={this.props.height}>
            <Pie dataKey="value" isAnimationActive={false} data={this.props.piechartData} cx={this.props.width / 2.3} cy={this.props.height / 2.3} fill="#8884d8" isAnimationActive={true} />

            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
