import { maxmSpeed } from "./Constants"

export function setUserDataToStorage(data) {
    localStorage.setItem('accessToken', JSON.stringify(data))
}

export function getUserDataFromStorage(data) {
    try {
        const serializedState = localStorage.getItem('userData')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export const distance = (lat1, lat2, lon1, lon2) => {

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    // console.log("distance", c * r * 1000 > 100 ? c * r * 1000 : '')

    return (c * r * 1000);
}

export const getTimeDifference = (startDate, endDate) => {
    return (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000
}

export const validateSpeed = (distance, time) => {
    if ((distance / time) > maxmSpeed) {
        return false
    }
    else {
        return true
    }
}

export const handleDatalogCoordinate = (data) => {
    var returnedData = []
    for (let i = 0; i < data.length; i++) {
        let key = Object.keys(data[i]).filter(item => item !== "xaxis")[0]
        if (data[i + 1] !== undefined) {
            if (getTimeDifference(data[i].xaxis, data[i + 1].xaxis) > 0 && validateSpeed(distance(data[i][key][0], data[i + 1][key][0], data[i][key][1], data[i + 1][key][1]), getTimeDifference(data[i].xaxis, data[i + 1].xaxis))) {
                returnedData.push(data[i])
            }
        }

    }
    return returnedData
}

export function mapDatatoCoordiante(data, useGPSKey = true) {
    let coordianate = [];
    for (let i = 0; i < data.length; i++) {
        if (useGPSKey) {
            try {
                coordianate.push({ lat: data[i].GPS[0], lng: data[i].GPS[1] })
            } catch (e) {
                let key = Object.keys(data[i]).filter(item => item !== "xaxis")[0]
                coordianate.push({ lat: data[i][key][0], lng: data[i][key][1] })
            }

        } else {
            coordianate.push({ lat: data[i][0], lng: data[i][1] })
        }
    }
    return coordianate;
}

export function mapCoordinateToArray(coordianate) {
    let coordianateArray = [];
    coordianate.map((data, index) => {
        coordianateArray.push([data.lat, data.lng]
        )
    });
    return coordianateArray;
}

export function removeArrayElementByIndex(array: array, index: Int) {
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}

export function updateLatLngArrayByIndex(array: array, lat, lng, index: Int) {
    let myArrya = array;
    myArrya[index].lat = lat;
    myArrya[index].lng = lng;
    return myArrya;
}

export function formatDate(date = new Date()) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
export function getFormatedDateAndTime(dateTime) {
    return (new Date(dateTime)).toDateString() + ' ' + (new Date(dateTime)).toLocaleTimeString()
}


export function validateEmail(mail) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(mail);
}
export function validateUsername(username) {
    var re = /^[a-zA-Z_\.\- ]+$/;
    return re.test(username);
}
export function validatePassword(password) {
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    return re.test(password);
}

export function validatePhoneNumber(number) {
    var re = /^(\+\d{1,3}[- ]?)?\d{10}$/
    return (re.test(number))
}

export function validateName(name) {
    var validate = ""
    var n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    var s = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
    for (var i = 0; i < name.length; i++) {
        if (name[i] in n) {
            validate = 'false'
            break
        }
        else if (s.indexOf(name.charAt(i)) != -1) {
            validate = 'false'
            break
        }
        else {
            validate = "true"
        }
    }
    return validate
}

export function changeDropdownArrayType(data) {
    var returnDataArray = []
    data.length > 0 && data.map((value, index) => {
        var returnDataObj = {}
        returnDataObj['name'] = value.thing_name
        returnDataObj['value'] = value.thing_id
        returnDataArray.push(returnDataObj)
    })
    return returnDataArray
}

export const getProjectName = (dataList) => {
    var x = dataList.map((item, index) => {
        return item.project
    })
    var y = new Set(x)
    y = [...y]
    return y
}

export const getThingsCount = (dataList, projectId) => {
    var x = 0
    dataList.map((item, index) => {
        if (projectId == item.project) {
            x = x + 1
        }
    })
    return x
}

export const getDatalogs = (list) => {
    var x = []
    list.map((item, index) => {
        if (item.gps.length > 0 && item.gps[0] !== 0) {
            x.push({
                "GPS": item.gps
            })
        }
    })
    return x
}

export const handleTotalDistance = (arr) => {
    var x = []
    arr.map((item, index) => {
        x.push(Object.values(item))
    })
    const result = [];

    for (let j = 1; j < x[0].length; j++) {
        let sum = 0;

        for (let i = 0; i < x.length; i++) {
            sum += parseInt(x[i][j]);
        }

        result.push(sum);
    }

    var y = ['Total', ...result]

    return y;
}