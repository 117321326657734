import React, { Component } from 'react'
import Footer from "./Footer"
import Header from "./Header";
import Client from './client';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default class Landingpage extends Component {
  state = {
    expanded: false,
    show: false,
    name: '',
    email: '',
    company: '',
    contactNo: '',
    subject: '',
    message: '',
    designation: ''
  };
  constructor(props) {
    super(props);
    this.aboutRef = React.createRef();
    this.serviceRef = React.createRef();
    this.VideoRef = React.createRef();
    this.ContactRef = React.createRef();


  }
  scrollToAbout = () => {
    this.aboutRef.current.scrollIntoView({ behavior: "smooth" });
  }
  scrollToService = () => {
    this.serviceRef.current.scrollIntoView({ behavior: "smooth" });
  }
  scrollToVideo = () => {
    this.VideoRef.current.scrollIntoView({ behavior: "smooth" });
  }
  scrollToContact = () => {
    this.ContactRef.current.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    return (
      <div>
        <Header scrollToAbout={this.scrollToAbout} scrollToService={this.scrollToService} scrollToVideo={this.scrollToVideo} scrollToContact={this.scrollToContact} />
        <section id="hero" className="d-flex align-items-center">
          <div
            className="container position-relative text-center text-lg-start"
            data-aos="zoom-in"
            data-aos-delay={100}
          >
            <div className="row">
              <div className="col-lg-8">
                <h1 style={{ fontFamily: "Open Sans,sans-serif" }}>
                  Welcome to <span>EPSUMTHINGS</span>
                </h1>
                <h2 style={{ fontFamily: "Open Sans,sans-serif" }}>
                  Embrace the IIoT Revolution with EpsumThings.
                </h2>
                <div className="btnd">
                  <a
                    href="/login"
                    className="btn-menu animated fadeInUp scrollto"
                    style={{ fontFamily: "Open Sans,sans-serif" }}
                  >
                    GET STARTED
                  </a>
                </div>
              </div>
            </div>
            <div className="rowo" style={{ paddingTop: "10px" }}>
              <div className="block">
                <div className="hovicon effect-4 sub-b " style={{ fontSize: 25 }}>
                  20+
                  <br />
                  <span style={{ fontSize: 20 }}>Clients</span>
                </div>
              </div>
              <div className="block">
                <div className="hovicon effect-4 sub-b" style={{ fontSize: 25 }}>
                  40+
                  <br />
                  <span style={{ fontSize: 20 }}>Projects</span>
                </div>
              </div>
              <div className="block">
                <div className="hovicon effect-4 sub-b" style={{ fontSize: 25 }}>
                  300+
                  <br />
                  <span style={{ fontSize: 20 }}>Users</span>
                </div>
              </div>
              <div className="block">
                <div className="hovicon effect-4 sub-b" style={{ fontSize: 25 }}>
                  1200+
                  <br />
                  <span style={{ fontSize: 20 }}>Devices</span>
                </div>
              </div>
              <div className="block">
                <div className="hovicon effect-4 sub-b" style={{ fontSize: 25 }}>
                  3Cr+
                  <br />
                  <span style={{ fontSize: 20 }}>Datapoints</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <>
          <main id="main" style={{ backgroundColor: "white" }} ref={this.VideoRef}>
            <section id="about" className="about" style={{ color: "black" }}>
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div
                    className="col-lg-6 order-1 order-lg-2"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <div className="about-img">
                      <video className='vdo-image'
                        height="300px"
                        controls
                        muted
                        autoPlay={"autoplay"}
                        preLoad="auto"
                        loop
                        style={{ paddingLeft: 80 }}
                      >
                        <source src="assets/img/video1.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                    <h3
                      style={{
                        fontFamily: "Open Sans,sans-serif",
                        fontSize: 20,
                        color: "#3b4550",
                        textAlign: "justify"
                      }}
                    >
                      Introducing a highly capable IoT/IIoT platform designed to simplify routine tasks and optimize process flow efficiency.
                    </h3>
                    <p
                      className=""
                      style={{
                        fontFamily: "Open Sans,sans-serif",
                        fontSize: 15,
                        textAlign: "justify",
                        paddingTop: 40
                      }}
                    >
                      Say goodbye to the hassle of juggling multiple apps and navigating a complex application to manage, monitor, and control every aspect of your process. Our platform offers user-friendly tools to deploy complete solutions and keep track of critical performance metrics, allowing you to make informed business decisions with ease.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section ref={this.serviceRef}
              id="services"
              className="services section-bg"
              style={{
                backgroundColor: "white",
                backgroundImage:
                  'url("https://epsumthings.com/static/media/bg-shapes.f1d483ab.svg")',
                backgroundSize: "cover"
              }}
            >
              <div className="container" data-aos="fade-up">
                <div className="section-title1">
                  <p style={{ fontFamily: "Open Sans,sans-serif" }}>
                    Check out the great Features
                  </p>
                </div>
                <div className="row">
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/Epsum Icons (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Manage all your Devices with ease</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        Grow your business by easliy managing large volumes of devices at once.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/2 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Handle your Projects hasslefree.</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        Easily manage multiple projects while controlling access to users through roles.{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/3 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Get notified of all your important parameters.</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        The parameters that are critical to your business need to be monitored continuously.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={400}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/4 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Build Dynamic Dashboards</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        Build customized dashboards shareable across the web and invoked at any point in time.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/5 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">
                          Monitor, Control and Visualize your processes using Webscada
                        </a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        You can visualize, control and monitor processes in one place and for any supported devices - PLCs, Sensors, Actuators etc.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/Epsum Icons.png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Create value using KPI Analytics</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        Using KPIs and other visual indicators, create business value through acute analytics.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/7 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">Integrate Multiple Platforms and work seamlessly</a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        Epsum Things Platform comes with well documented RESTful APIs and SDK that enable you to build customized solutions for your business.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={400}
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <img src="assets/img/6 (1).png" height="50px" />
                      </div>
                      <h4
                        className="title"
                        style={{ fontFamily: "Open Sans,sans-serif", fontSize: 20 }}
                      >
                        <a href="">
                          Automate any mundane or complex process with Process Automation.
                        </a>
                      </h4>
                      <p
                        className=""
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Open Sans,sans-serif",
                          fontSize: 15
                        }}
                      >
                        By using the Process Automation module, automate any trivial and non-trivial processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="container">
  <img src="assets/img/w2.gif" height="300px" width="1300px" ></div> */}
            </section>
            {/* <div class="container">
<img src="assets/img/w3.gif" height="300px" width="1300px" ></div> */}
            <section ref={this.aboutRef} id="about">
              <div className="container">
                <div className="section-title1">
                  {/* <h2 style="font-family: Open Sans,sans-serif;">Services</h2> */}
                  <p style={{ fontFamily: "Open Sans,sans-serif" }}>Architecture</p>
                </div>
                <img
                  className="new"
                  src="assets/img/w2.gif"
                  height="300px"
                  width="1300px"
                />
              </div>
            </section>
            <section
              id="specials"
              className="specials"
              style={{ backgroundColor: "white", marginTop: "50px" }}
            >
              <div className="container" data-aos="fade-up">
                <div className="section-title1">
                  {/* <h2 style="color: black;font-family: Open Sans,sans-serif;">lorem</h2> */}
                  <p style={{ fontSize: 20, fontFamily: "Open Sans,sans-serif" }}>
                    {" "}
                    EpsumThings has the potential to revolutionize various aspects of life, including residential, commercial, and industrial settings, as well as urban landscapes. By opting EpsumThings, individuals and organizations gain enhanced control and monitoring capabilities, allowing for efficient and streamlined operations. From locating lost items to overseeing an entire enterprise with a single click, EpsumThings empowers users with all the necessary tools. {" "}
                  </p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                  <div className="col-lg-3">
                    <ul className="nav nav-tabs flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link active show"
                          data-bs-toggle="tab"
                          href="#tab-1"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Intelligent Visual Monitoring
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-2"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Web SCADA
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-3"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Freight Monitoring
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-4"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Multi-Platform Integration
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-5"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Improve Network Uptime
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-6"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          No Code Platform
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-7"
                          style={{ fontFamily: "Open Sans,sans-serif" }}
                        >
                          Dynamic Dashboard
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-9 mt-4 mt-lg-0">
                    <div className="tab-content">
                      <div className="tab-pane active show" id="tab-1">
                        <div className="row">
                          <div className="col-lg-7 details order-2 order-lg-1">
                            <h3 style={{ color: "black" }}>
                              Intelligent Visual Monitoring
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              The use of cameras in an automation scenario enables businesses to monitor processes in real-time, providing stakeholders with a visual evaluation of the results of automation. The use of cameras in automation can be beneficial in various sectors, including manufacturing, logistics, and transportation, where accurate and efficient monitoring of the process is critical.{" "}
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              EpsumThings provides a user-friendly platform that allows businesses to integrate cameras with their automation environment in no time. With EpsumThings, businesses can monitor and evaluate automation results using real-time visual data, making it easier for stakeholders to identify potential issues and improve overall efficiency.
                            </p>
                          </div>
                          <div className="col-lg-5 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/a1.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-2">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1" style={{
                            aspectRatio: " 2/1",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}>
                            <h3 style={{ color: "black" }}>
                              Web SCADA
                            </h3>

                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              EpsumThings WebSCADA is an intuitive, user-friendly platform that provides real-time monitoring and control of industrial processes from a single interface. With its advanced features, businesses can monitor and control multiple industrial systems, such as HVAC, electrical systems, and production lines, from a single location. This makes it easier for stakeholders to track key performance indicators (KPIs), identify potential issues, and optimize industrial processes.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>One of the key advantages of EpsumThings WebSCADA is its scalability. The platform can be scaled up or down based on the needs of the business. This means that businesses can expand their monitoring and control capabilities without incurring additional costs. With EpsumThings WebSCADA, businesses can easily integrate new equipment and systems into their industrial control system, ensuring maximum visibility and efficiency.</p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              EpsumThings WebSCADA is also designed with security in mind. The platform is equipped with advanced security features, such as user access control, to ensure that only authorized personnel have access to critical industrial systems. This ensures that the business remains compliant with industry regulations while safeguarding against security breaches.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              The parameters that are critical to your business need to be monitored continuously. With alarms, you'll receive notifications as soon as an abnormality occurs, so you can take action almost instantly. Trends will guide you in understanding the parameters in detail.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)", fontSize: "15px" }}>
                              Click to share features gives you a time based restricted access to SCADA pages.<br />Access WebSCADA on mobile app as well.


                            </p>

                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/scada.png"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-3">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1" style={{
                            aspectRatio: " 2/1",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}>
                            <h3 style={{ color: "black" }}>
                              Freight Monitoring
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              EpsumThings GPS-based freight monitoring system is a powerful tool that allows businesses to track the movement of their cargo in real-time. The system uses advanced GPS technology to provide accurate location data, making it easier for businesses to optimize their freight management processes and respond quickly to potential issues.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              The platform is user-friendly and provides businesses with real-time visibility into the location and status of their cargo. This makes it easier for businesses to track and monitor their shipments, ensuring that they reach their destination on time and in good condition. Additionally, the platform allows for quick identification of potential issues, such as delays or detours, enabling businesses to take corrective action promptly.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              One of the key advantages of EpsumThings GPS-based freight monitoring system is its advanced security features. The system is equipped with an alarm system and geo fencing that triggers alerts in the event of deviation in route or fuel theft. This ensures that businesses can respond quickly to security breaches and protect their cargo from potential theft.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              EpsumThings GPS-based freight monitoring system is also designed to integrate seamlessly with existing freight management systems. This means that businesses can quickly and easily incorporate the platform into their existing workflows without the need for extensive training or system modifications.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/a2.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-4">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3 style={{ color: "black" }}>
                              Multi-Platform Integration
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              Integrate Multiple Platforms and work seamlessly
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              Epsum Things Platform comes with well documented RESTful APIs and SDK that enable you to build customized solutions for your business.
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              We also provide webhooks to facilitate seamless integration with other platforms.
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/a4.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-5">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3 style={{ color: "black" }}>
                              Improve Network Uptime
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              Reduce Downtime By Improving Uptime & Network Scalability{" "}
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              Avoid Network Congestion.<br />
                              Using HTTP, MQTT and websockets, EpsumThings sends data immediately as it happens in the hardware, almost instantly with almost zero latency.

                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/a5.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-6">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1" style={{
                            aspectRatio: " 2/1",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}>
                            <h3 style={{ color: "black" }}>
                              No Code Platform
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              In today's fast-paced business world, process automation is becoming increasingly critical for streamlining operations and improving productivity. EpsumThings, a leading provider of automation solutions, offers a no-code process automation tool that simplifies the automation process and empowers businesses to automate their processes in a more efficient way.{" "}
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              EpsumThings' no-code process automation tool is designed with the end-user in mind, allowing businesses to automate their processes without the need for specialized coding skills. The platform utilizes drag-and-drop nodes, making it simple to build process flows and generate code, thereby reducing the time and effort required to automate processes. The intuitive interface and visual representation of the automation workflows simplify the automation process for stakeholders.

                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              One of the key advantages of EpsumThings' no-code automation tool is its flexibility. The platform is highly customizable, allowing businesses to tailor their automation workflows to their specific requirements. This flexibility ensures that the platform can be integrated seamlessly into existing workflows and can be scaled to meet the evolving needs of the business.{" "}
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/nocode.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-7">
                        <div className="row">
                          <div className="col-lg-8 details order-2 order-lg-1" style={{
                            aspectRatio: " 2/1",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}>
                            <h3 style={{ color: "black" }}>
                              Dynamic Dashboard
                            </h3>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              EpsumThings' dynamic dashboard feature provides businesses with a comprehensive view of their data, making it easier to monitor key performance indicators (KPIs), identify trends, and track progress against targets. The platform offers a user-friendly interface that can be customized to meet the specific needs of the business, and its real-time data visualization capabilities enable stakeholders to quickly identify areas that require attention.{" "}
                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              One of the key advantages of EpsumThings' dynamic dashboard feature is its flexibility. The platform allows businesses to select and display specific data sets, making it easier to analyze data that is relevant to their operations. The data displayed can be tailored to meet the needs of different stakeholders, ensuring that everyone has access to the information they need to make informed decisions.

                            </p>
                            <p style={{ color: "rgba(3, 63, 71, 0.847)" }}>
                              One can simply use features like add to dashboard and drag to resize to customize your dashboard as per your convenience with out doing any piece of coding.

                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img
                              src="assets/img/kpi.webp"
                              alt=""
                              className="img-fluid"
                              style={{ height: 300, width: 450 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ======= Chefs Section ======= */}
            <section id="chefs" className="chefs" style={{ backgroundColor: "white", marginTop: "50px" }}>
              <div className="container" data-aos="fade-up">
                <div className="section-title1">
                  <h2
                    style={{
                      fontFamily: "Open Sans,sans-serif",
                      color: "orangered",
                      fontSize: 30
                    }}
                  >
                    <b>Success Stories</b>
                  </h2>
                  {/* <p style="font-family: Poppins,sans-serif;">Our Blogs</p> */}
                  <div className="success">
                    <img
                      className="side"
                      src="assets/img/new4.png"
                      height="700px"
                      width="1300px"
                    />
                    <button
                      className="custom-btn btn-5"
                      style={{ marginLeft: 550, width: 200 }}
                    >
                      <span>Know more</span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="call-action-area call-action-one container"
              style={{
                backgroundImage:
                  'url("https://www.kochartech.com/wp-content/uploads/2019/08/img-become-partner-banner.png")',
                backgroundSize: "cover",marginTop: "50px"
              }}
            >
              <div className="container">
                <div className="row align-items-center call-action-content">
                  <div className="col-xl-8 col-lg-8">
                    <div className="call-action-text">
                      <h2 className="action-title">Become A Partner</h2>
                      <p className="text-lg">
                        We would like to invite you to explore a potential partnership with us!!
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="call-action-btn rounded-buttons text-lg-end">
                      <a
                        href="javascript:void(0)"
                        className="btn primary-btn rounded-full"
                      >
                        <button className="custom-btn btn-5" onClick={() => { this.setState({ show: true }) }}>
                          <span>Be A Partner</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                {/* row */}
              </div>
              {/* container */}
            </section>


            <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }}>
              <Modal.Header closeButton style={{ backgroundColor: "orangered" }}>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body style={{
                aspectRatio: " 6/4",
                // overflowX: "hidden",
                // overflowY: "scroll",
              }}>
                <Form onSubmit={this.handleFormSubmit}>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" value={this.state.name} required name="name" onChange={this.handleInputChange} />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" value={this.state.email} required name="email" onChange={this.handleInputChange} />
                  </Form.Group>

                  <Form.Group controlId="company">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your company name" value={this.state.company} required name="company" onChange={this.handleInputChange} />
                  </Form.Group>

                  {/* <Form.Group controlId="contactNo">
          <Form.Label>Contact No</Form.Label>
          <Form.Control type="tel" placeholder="Enter your contact number" value={this.state.contactNo} required name="contactNo" onChange={this.handleInputChange} />
        </Form.Group> */}

<PhoneInput
                                                containerStyle={{ width: "190px",marginTop: "30px" }}
                                                inputStyle={{ width: "470px", height: "35px" }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.phone}
                                                onChange={event => { this.setState({ phone: event }); }} style={{color: "black", marginTop: "30px"}}
                                            />

                  <Form.Group controlId="designation">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" placeholder="Enter your designation" value={this.state.designation} required name="designation" onChange={this.handleInputChange} />
                  </Form.Group>

                  <Form.Group controlId="subject"> 
                    <Form.Label>Subject </Form.Label>
                    <Form.Control  as="select" required value={this.state.subject} name="subject" onChange={this.handleInputChange}>
                      <option value="">Oppurtunity for partnership  </option>
                      <option value="General Inquiry">General Inquiry</option>
                      <option value="Sales Inquiry">Sales Inquiry</option>
                      {/* <option value="Support Request">Support Request</option> */}
                    </Form.Control>
                    {/* <span style={{color:"red",marginTop:"1rem"}}><i class="fa fa-caret-down" aria-hidden="true"></i></span> */}

                  </Form.Group>
                
                  <Form.Group controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={this.state.message} required name="message" onChange={this.handleInputChange} />
                  </Form.Group>



                  <Button variant="primary" type="submit" style={{ marginTop: "5px", backgroundColor: "orangered", border: "none" }}>
                    Submit
                  </Button>
                </Form>
              </Modal.Body>
              {/* <Modal.Footer>
                  <Button variant="secondary" onClick={() => { this.setState({ show: false }) }}>
                    Close
                  </Button>
                  <Button  variant="primary" style={{backgroundColor: "orangered", border: "none"}}  onClick={() => { this.setState({ show: false })}}>
                    Save Changes
                  </Button>
                </Modal.Footer> */}
            </Modal>
            {/* testimonial */}
            <section
              id="chefs"
              className="chefs"
              style={{ backgroundColor: "white" ,marginTop: "50px"}}
            >
              <div className="container" data-aos="fade-up" >
                <div className="section-title1">
                  <h2
                    className="type"
                    style={{
                      fontFamily: "Open Sans,sans-serif",
                      color: "orangered",
                      fontSize: 30
                    }}
                  >
                    <b>What Our Clients Say About Us</b>
                  </h2>
                  <Client />
                  {/* <p style="font-family: Poppins,sans-serif;">Our Blogs</p> */}
                  {/* <img
                    className="client"
                    src="assets/img/te3.png"
                    height="750px"
                    width="1300px"
                  /> */}
                  {/* <img src="assets/img/50.png" height="300px" width="2000px" style="padding-right:50px;" > */}
                  {/* <div style="background-image: url('assets/img/bg3.png');"> */}
                  {/* <img src="assets/img/ww.png"  > */}
                </div>
              </div>
            </section>
            {/* ======= Contact Section ======= */}
          </main>
          {/* End #main */}
        </>



        <footer id="footer" ref={this.ContactRef} style={{ backgroundColor: "#3b4550" }}>
          <div className="footer-top" style={{ backgroundColor: "#3b4550" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  {/* <img class="green" src="./assets/img/green.png" height="150px" style="margin-left:200px;"> */}
                  <div className="row" style={{ marginTop: 50 }}>
                    <div className="col-sm-3">
                      <img
                        src="https://www.epsumlabs.com/images/headerimg1.png"
                        height="25px"
                      />
                    </div>
                    <div className="col-sm-3">
                      <img
                        src="https://www.epsumlabs.com/images/headerimg2.png"
                        height="25px"
                      />
                    </div>
                    <div className="col-sm-3">
                      <img
                        src="https://www.epsumlabs.com/images/headerimg3.png"
                        height="25px"
                      />
                    </div>
                    <div className="col-sm-3">
                      <img
                        src="https://www.epsumlabs.com/images/headerimg5.png"
                        height="25px"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4 style={{ paddingLeft: 10 }}>Reach Us</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" /> (+91)-7377073774
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" /> (+91)-6743589818
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" /> info@epsumlabs.com
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" /> www.epsumlabs.com
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4 style={{ paddingLeft: 10 }}>Quick Link</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" /> <a className="ab" href="/">Helps</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a className="ab" href="#service">Features</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a className="ab" href="#">Videos</a>
                    </li>
                    {/* <li><i class="bx bx-chevron-right"></i> <a className="ab" href="#">Marketing</a></li>
        <li><i class="bx bx-chevron-right"></i> <a className="ab" href="#">Graphic Design</a></li> */}
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-newsletter">
                  <div className="social-links mt-3">
                    <h4 style={{ paddingLeft: 30 }}>Social Media</h4>
                    <a href="https://twitter.com/epsumlabs" className="twitter">
                      <i className="bx bxl-twitter" />
                    </a>
                    <a href="https://www.facebook.com/epsumlabs/" className="facebook">
                      <i className="bx bxl-facebook" />
                    </a>
                    <a
                      href="https://www.instagram.com/epsumlabs/"
                      className="instagram"
                    >
                      <i className="bx bxl-instagram" />
                    </a>
                    {/* <a className="ab" href="#" class="google-plus"><i class="bx bxl-skype"></i></a> */}
                    <a
                      href="https://in.linkedin.com/company/epsumlabs"
                      className="linkedin"
                    >
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span style={{ color: "orangered" }}>Epsum Labs Pvt. Ltd.</span>
              </strong>{" "}
              All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/restaurantly-restaurant-template/ */}
              {/* Designed by <a className="ab" href="https://bootstrapmade.com/">BootstrapMade</a> */}
            </div>
          </div>
        </footer>


      </div>
    )
  }
}
