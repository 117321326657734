import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AlarmIcon from '@material-ui/icons/AlarmOn';
import Alarmtable from './Alarmtable';


export default class Alarmfeed extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.avtarClick = this.avtarClick.bind(this);
    this.state = {
      popupVisible: false
    };
  }

  avtarClick(e) {
    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible,
    }));
    e.preventDefault();
  }

  handleChange(checked) {
    this.setState({ checked });
  }
  render() {

    return (
      <div className="popover-container" ref={node => { this.node = node; }}>
        <Grid container>
          {/* <Fab size="small" color="secondary" onClick={this.avtarClick} toggle="true" Popover aria-label="Add" id="alarm_feed" >
               <AlarmIcon />
            </Fab> */}
          {this.state.popupVisible && (
            <div className="popover alarmtable_feed">
              <h5 className="listitemtext">Alarm Feed</h5>
              <Alarmtable />
            </div>
          )}
        </Grid>
      </div>
    );
  }
}
