import { WEBHOOKS_LIST, SELECTED_PAGE_NO_OF_WEBHOOK, LOGOUT } from '../types';

const initialState = {

    webHooksList: [],
    webHookCount: 0,
    webhooks_selected_page_no: '',
}

const WebhooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEBHOOKS_LIST:
            return {
                ...state,
                webHooksList: action.payload.data,
                webHookCount: action.payload.count
            }
        case SELECTED_PAGE_NO_OF_WEBHOOK:
            return {
                ...state,
                webhooks_selected_page_no: action.payload,
            }
        case LOGOUT:
            return {
                ...state,
                webHooksList: [],
                webHookCount: 0,
                webhooks_selected_page_no: '',
            }
        default:
            return state;
    }

};

export default WebhooksReducer;