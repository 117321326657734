import {
    SCOPE_LIST,
} from '../types';

const initialState = {

    scopeList: []
}

const scopeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCOPE_LIST:
            return {
                ...state,
                scopeList: action.payload
            }
        default:
            return state;
    }

};

export default scopeReducer;