import {
    IR_REMOTES_LIST,
} from '../types';

const initialState = {

    irRemotesList: []
}

const irReducer = (state = initialState, action) => {
    switch (action.type) {
        case IR_REMOTES_LIST:
            return {
                ...state,
                irRemotesList: action.payload
            }
        default:
            return state;
    }

};

export default irReducer;