import { THINGS_LIST, SELECTED_PAGE_NO, LOGOUT } from '../types';

const initialState = {

    thingList: [],
    count: 0,
    selected_page_no: '',
}

const thingsReducer = (state = initialState, action) => {
    switch (action.type) {
        // case THINGS_LIST:
        //     if (action.payload.length < 1)
        //         return {
        //             ...state,
        //             thingList: [],
        //             count: action.payload.count
        //         }
        //     else
        //         return {
        //             ...state,
        //             thingList: state.thingList.concat(action.payload.data.things),
        //             count: action.payload.count
        //         }
        case THINGS_LIST:
            return {
                ...state,
                thingList: action.payload.data.things,
                count: action.payload.count
            }
        case SELECTED_PAGE_NO:
            return {
                ...state,
                selected_page_no: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                thingList: [],
                count: 0,
                selected_page_no: '',
            }
        default:
            return state;
    }

};

export default thingsReducer;