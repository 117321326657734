import { FLASH_NOTIFICATION } from '../types'
import { NOTIFICATION_COLOUR } from '../../constants/api'

export function setFlashNotification(message = '', color = NOTIFICATION_COLOUR.SUCCESS) {
    return function (dispatch) {
        dispatch({ type: FLASH_NOTIFICATION, payload: { message: message, color: color, visibility: true } })
    }
}

export function unsetFlashNotification() {
    return function (dispatch) {
        dispatch({ type: FLASH_NOTIFICATION, payload: { message: '', color: '', visibility: false } })
    }
}
